import {
  Upload,
  Button,
  message,
  Table,
  Select,
  Typography,
  Row,
  Col,
  List,
  Tooltip,
  Form,
} from "antd";
import dayjs from "dayjs";
import {
  UploadOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
  ExclamationCircleOutlined,
  CopyOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import React, { useEffect } from "react";
import useScreenSize from "../../../layouts/useScreenSize";
import { StockOperationCreationPayload } from "../../../api/models";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../../api";
import CollapsibleCard from "../../../components/collapsibleCard";
import {
  convertNumberStringToValidNumber,
  NumberFormats,
} from "../../../services/utils";
import WalletSelection from "../../../components/walletSelection";

const enum FileFormats {
  PROFIT = "Profit",
  Fiainvest = "Fiainvest",
}

type ParsedRow = {
  key: number;
  date: string;
  ticker: string;
  amount: string;
  price: string;
  wallet?: string;
  existingOperation?: boolean;
  preValidationMessage: string;
  validationMessage: string;
  isValidRow: boolean;
  action: ImportActions;
  isDateEdited?: boolean;
  isTickerEdited?: boolean;
  isAmountEdited?: boolean;
  isPriceEdited?: boolean;
};

const enum ImportActions {
  IMPORT = "Importar",
  IGNORE = "Ignorar",
}

const validateRow = (
  row: ParsedRow,
  numberFormat: NumberFormats,
  isEditing: boolean,
) => {
  let parsedDate = row.date;
  let amount = row.amount;
  let price = row.price;

  // processamento de campos
  let message = "";
  let isValidRow = true;

  let convertedDate = dayjs(row.date, "YYYY-MM-DD").format("YYYY-MM-DD");
  if (convertedDate === "Invalid Date") {
    convertedDate = dayjs(row.date, "DD/MM/YYYY").format("YYYY-MM-DD");
    if (convertedDate === "Invalid Date") {
      message += "Data inválida.";
      isValidRow = false;
    } else {
      parsedDate = convertedDate;
    }
  } else {
    parsedDate = convertedDate;
  }

  amount = convertNumberStringToValidNumber(row.amount, numberFormat, false);
  if (!isEditing) {
    price = convertNumberStringToValidNumber(row.price, numberFormat, true);
  }

  const parsedAmount = Number(amount);
  if (!Number.isInteger(parsedAmount)) {
    message += "Quantidade inválida.";
    isValidRow = false;
  }

  const parsedPrice = Number(price);
  if (Number.isNaN(parsedPrice)) {
    message += "Preço inválido.";
    isValidRow = false;
  }

  return {
    ...row,
    date: parsedDate,
    amount: parsedAmount.toString(),
    price: parsedPrice.toString(),
    preValidationMessage: message,
    isValidRow,
    action: isValidRow ? ImportActions.IMPORT : ImportActions.IGNORE,
  };
};

const OperationsImport = () => {
  const queryClient = useQueryClient();
  const { isDesktop } = useScreenSize();
  const [fileFormat, setFileFormat] = React.useState<FileFormats | null>(null);
  const [isValidated, setIsValidated] = React.useState<boolean>(false);
  const [rowsToImport, setRowsToImport] = React.useState<string[]>([]);
  const [parsedRows, setParsedRows] = React.useState<ParsedRow[]>([]);
  const [numberFormat, setNumberFormat] = React.useState<NumberFormats>(
    NumberFormats.BRAZILIAN,
  );
  const [selectedPage, setSelectedPage] = React.useState<number>(1);

  const uploadReader = (file: File) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const result = e?.target?.result;
      if (typeof result === "string") {
        const rows = result.split("\n");
        setRowsToImport(rows);
        setParsedRows([]);
        setIsValidated(false);
      } else {
        message.error("Erro ao ler arquivo, seu conteúdo não é válido.");
      }
    };
    reader.readAsText(file);

    // Prevent upload
    return false;
  };

  const markIgnoreExerciseOperations = (rows: ParsedRow[]) => {
    const newParsedRows = rows.map((row) => {
      if (row.action === ImportActions.IGNORE || !row.isValidRow) {
        return row;
      }
      // se o ticker termina em E, marca como ignorado
      const isExerciseOperation = row.ticker.endsWith("E");
      if (isExerciseOperation) {
        return { ...row, action: ImportActions.IGNORE };
      }
      return row;
    });
    setParsedRows(newParsedRows);
    message.info(
      "Operações de exercício de opções foram marcadas para serem ignoradas.",
    );
  };

  const validationMutation = useMutation({
    mutationFn: (values: ParsedRow[]) => {
      const payload = values.map((row) => {
        const payloadItem: StockOperationCreationPayload = {
          date: row.date,
          ticker: row.ticker,
          amount: parseInt(row.amount),
          price: row.price,
          wallet: row.wallet,
        };
        return payloadItem;
      });
      const filteredPayload = payload.filter(
        (row): row is StockOperationCreationPayload => row !== null,
      );
      return appApi.stockOperations.validateOperations({
        params: filteredPayload,
      });
    },
    onSuccess: (response) => {
      if (response) {
        const newParsedRows = parsedRows.map((row) => {
          const operation = response.find((record) =>
            record?.date === row.date &&
            record?.ticker === row.ticker &&
            record?.amount
              ? record.amount.toString()
              : undefined === row.amount && record?.price === row.price,
          );
          if (operation) {
            return {
              ...row,
              existingOperation: operation.existingOperation,
              validationMessage: operation.message,
              isValidRow: !operation.isBlocking,
              action: ImportActions.IGNORE,
            };
          } else {
            return {
              ...row,
              existingOperation: false,
              validationMessage: "",
              isValidRow: true,
              action: ImportActions.IMPORT,
            };
          }
        });
        setParsedRows(newParsedRows);
        setIsValidated(true);
      }
    },
  });

  const importMutation = useMutation({
    mutationFn: (values: ParsedRow[]) => {
      const payload = values.map((row) => {
        if (row.action === ImportActions.IGNORE) {
          return null;
        }
        const payloadItem: StockOperationCreationPayload = {
          date: row.date,
          ticker: row.ticker,
          amount: parseInt(row.amount),
          price: row.price,
          wallet: row.wallet,
          description: "Operação importada",
        };
        return payloadItem;
      });
      const filteredPayload = payload.filter(
        (row): row is StockOperationCreationPayload => row !== null,
      );
      return appApi.stockOperations.bulkCreate(filteredPayload);
    },
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["operationsHistory"] });
      queryClient.invalidateQueries({ queryKey: ["wallets"] });
      queryClient.invalidateQueries({ queryKey: ["currentPositions"] });
      queryClient.invalidateQueries({ queryKey: ["accounts"] });
      message.success("Operações importadas com sucesso.");
      setParsedRows([]);
      setRowsToImport([]);
    },
  });

  useEffect(() => {
    if (rowsToImport.length > 0) {
      const parsedRows = rowsToImport.map((row, index) => {
        let parsedDate = "";
        let ticker = "";
        let amount = "";
        let price = "";

        // se a linha for vazia ou só tiver espacos em branco ignora
        if (row.trim() === "") {
          return null;
        }

        if (fileFormat === FileFormats.PROFIT) {
          // cabecalho do arquivo profit fica na segunda linha
          // Corretora;Conta;Titular;ClOrdID;Ativo;Lado;Status;Criação;Última Atualização;Preço;Preço Stop;Qtd;Preço Médio;Qtd Executada;Qtd restante;Total;Total Executado;Validade;Data Validade;Estratégia;Mensagem;Carteira
          if (index < 2) {
            return null;
          }
          const fieldsList = row.split(";");
          let orderStatus = fieldsList[6];
          // substitui a palvra inicial que se iniciar com Execu* por Executada, mas somente a plavra inicial

          const orderStatusArray = orderStatus.split(" ");
          if (orderStatusArray[0].startsWith("Execu")) {
            orderStatusArray[0] = "Executada";
          }
          orderStatus = orderStatusArray.join(" ");
          if (
            orderStatus !== "Executada" &&
            orderStatus !== "Executada Parcial Expirada" &&
            orderStatus !== "Executada Parcial"
          ) {
            return null;
          }
          parsedDate = fieldsList[8].split(" ")[0];
          ticker = fieldsList[4];
          // pega amount da qtd Executada
          amount = fieldsList[13];

          const side = fieldsList[5];
          if (side === "V") {
            amount = `-${amount}`;
          }
          price = fieldsList[12];
        } else {
          // cabecalho do arquivo fiainvest fica na primeira linha
          // Data;Ativo;Quantidade;Preço;Carteira;Operação
          if (index === 0) {
            return null;
          }
          [parsedDate, ticker, amount, price] = row.split(";");
        }
        if (
          (parsedDate === "" || parsedDate === null) &&
          (ticker === "" || ticker === null) &&
          (amount === "" || amount === null) &&
          (price === "" || price === null)
        ) {
          return null;
        }

        let parsedRow = {
          key: index,
          date: parsedDate,
          ticker: ticker,
          amount: amount,
          price: price,
          preValidationMessage: "",
          validationMessage: "",
          isValidRow: true,
          action: ImportActions.IMPORT,
        };
        return validateRow(parsedRow, numberFormat, false);
      });
      const filteredRows = parsedRows.filter(
        (row): row is ParsedRow => row !== null,
      );
      setParsedRows(filteredRows);
      setIsValidated(false);
    }
  }, [rowsToImport, fileFormat, numberFormat]);

  const setRowWallet = (key: number, wallet: string) => {
    const newParsedRows = parsedRows.map((row) => {
      if (row.key === key) {
        return { ...row, wallet };
      }
      return row;
    });
    setParsedRows(newParsedRows);
    setIsValidated(false);
  };

  const setRowAction = (key: number, action: ImportActions) => {
    const newParsedRows = parsedRows.map((row) => {
      if (row.key === key) {
        return { ...row, action };
      }
      return row;
    });
    setParsedRows(newParsedRows);
  };

  const renderWalletSelection = (value: string | undefined, row: ParsedRow) => {
    return (
      <WalletSelection
        onChange={(value) => setRowWallet(row.key, value)}
        placeholder="Carteira"
        className=" min-w-32"
        value={value}
      />
    );
  };

  const renderActionSelection = (
    value: ImportActions | undefined,
    row: ParsedRow,
  ) => {
    return (
      <div className="flex justify-between">
        <Select
          onChange={(value) => setRowAction(row.key, value)}
          placeholder="Ação a realizar"
          className=" min-w-32"
          value={value}
          disabled={!row.isValidRow}
        >
          <Select.Option value={ImportActions.IMPORT}>Importar</Select.Option>
          <Select.Option value={ImportActions.IGNORE}>Ignorar</Select.Option>
        </Select>
        <Tooltip title="Duplicar operação">
          <Button
            type="default"
            onClick={() => duplicateOperation(row)}
            icon={<CopyOutlined />}
          />
        </Tooltip>
        <Tooltip title="Deletar operação">
          <Button
            type="default"
            onClick={() => deleteOperation(row)}
            danger
            icon={<DeleteOutlined />}
          />
        </Tooltip>
      </div>
    );
  };

  const renderPreValidationMessage = (record: ParsedRow) => {
    if (!record?.preValidationMessage && !record?.validationMessage) {
      return <CheckCircleOutlined style={{ color: "green", fontSize: 20 }} />;
    }
    let message = record?.preValidationMessage
      ? record.preValidationMessage
      : "";
    if (record?.validationMessage) {
      message += record.validationMessage;
    }
    return (
      <Tooltip title={message}>
        <CloseCircleOutlined style={{ color: "red", fontSize: 20 }} />
      </Tooltip>
    );
  };

  const editPrice = (record: ParsedRow, price: string) => {
    const newParsedRows = parsedRows.map((row) => {
      if (row.key === record.key) {
        let newRow = { ...row, price, isPriceEdited: true };
        return validateRow(newRow, numberFormat, true);
      }
      return row;
    });
    setParsedRows(newParsedRows);
    setIsValidated(false);
  };

  const editAmount = (record: ParsedRow, amount: string) => {
    const newParsedRows = parsedRows.map((row) => {
      if (row.key === record.key) {
        let newRow = { ...row, amount, isAmountEdited: true };
        return validateRow(newRow, numberFormat, true);
      }
      return row;
    });
    setParsedRows(newParsedRows);
    setIsValidated(false);
  };

  const editDate = (record: ParsedRow, date: string) => {
    const newParsedRows = parsedRows.map((row) => {
      if (row.key === record.key) {
        let newRow = { ...row, date, isDateEdited: true };
        return validateRow(newRow, numberFormat, true);
      }
      return row;
    });
    setParsedRows(newParsedRows);
    setIsValidated(false);
  };

  const editTicker = (record: ParsedRow, ticker: string) => {
    const newParsedRows = parsedRows.map((row) => {
      if (row.key === record.key) {
        return { ...row, ticker, isTickerEdited: true };
      }
      return row;
    });
    setParsedRows(newParsedRows);
    setIsValidated(false);
  };

  const duplicateOperation = (record: ParsedRow) => {
    // get the max existing key in the array and add 1
    const newKey = Math.max(...parsedRows.map((row) => row.key)) + 1;
    let newRow = { ...record, key: newKey };

    const newParsedRows = [newRow, ...parsedRows];
    setParsedRows(newParsedRows);
    setIsValidated(false);
    setSelectedPage(1);
  };

  const deleteOperation = (record: ParsedRow) => {
    const newParsedRows = parsedRows.filter((row) => row.key !== record.key);
    setParsedRows(newParsedRows);
    setIsValidated(false);
    setSelectedPage(1);
  };

  const renderExistingOperation = (value: boolean | null | undefined) => {
    if (value === null || value === undefined) {
      return "Validação pendente";
    }
    if (value === false) {
      return (
        <Tooltip title="Nenhuma operação idêntica encontrada">
          <CheckCircleOutlined style={{ color: "green", fontSize: 20 }} />
        </Tooltip>
      );
    }
    return (
      <Tooltip
        title={
          "Já existe uma operação registrada nesta mesma data para o mesmo ativo e quantidade. Se tiver sido realizada uma segunda operação com os mesmos parâmetros você pode optar por escolher a ação Importar."
        }
      >
        <ExclamationCircleOutlined style={{ color: "orange", fontSize: 20 }} />
      </Tooltip>
    );
  };

  const columns = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      render: (value: string, record: ParsedRow) => (
        <Typography.Text
          editable={{
            onChange: (value) => editDate(record, value),
          }}
        >
          {value}
        </Typography.Text>
      ),
    },
    {
      title: "Ativo",
      dataIndex: "ticker",
      key: "ticker",
      onFilter: (value: any, record: ParsedRow) =>
        record.ticker.startsWith(value as string),
      filters: [...new Set(parsedRows.map((row) => row.ticker)).values()].map(
        (ticker) => ({ text: ticker, value: ticker }),
      ),
      sorter: (a: ParsedRow, b: ParsedRow) => a.ticker.localeCompare(b.ticker),
      filterSearch: true,
      render: (value: string, record: ParsedRow) => (
        <Typography.Text
          editable={{
            onChange: (value) => editTicker(record, value),
          }}
        >
          {value}
        </Typography.Text>
      ),
    },
    {
      title: "Quantidade",
      dataIndex: "amount",
      key: "amount",
      render: (value: string, record: ParsedRow) => (
        <Typography.Text
          editable={{
            onChange: (value) => editAmount(record, value),
          }}
        >
          {value}
        </Typography.Text>
      ),
    },
    {
      title: "Preço",
      dataIndex: "price",
      key: "price",
      render: (value: string, record: ParsedRow) => (
        <Typography.Text
          editable={{
            onChange: (value) => editPrice(record, value),
          }}
        >
          {value}
        </Typography.Text>
      ),
    },
    {
      title: "Status",
      dataIndex: "message",
      key: "preValidationMessage",
      render: (_: string, record: ParsedRow) =>
        renderPreValidationMessage(record),
    },
    {
      title: "Operação nova?",
      dataIndex: "existingOperation",
      key: "existingOperation",
      render: (value: boolean | null | undefined) =>
        renderExistingOperation(value),
    },
    {
      title: "Carteira",
      dataIndex: "wallet",
      key: "wallet",
      render: (value: string, row: ParsedRow) =>
        renderWalletSelection(value, row),
    },
    {
      title: "Ação",
      dataIndex: "action",
      key: "action",
      render: (value: ImportActions, row: ParsedRow) =>
        renderActionSelection(value, row),
    },
  ];

  const importCount = parsedRows.filter(
    (row) => row.action === ImportActions.IMPORT,
  ).length;

  return (
    <CollapsibleCard title="Importar operações" collapsible={false}>
      <Col className="w-full">
        <Typography.Paragraph>
          São aceitos arquivos nos formatos .txt e .csv.
        </Typography.Paragraph>
        <Typography.Paragraph>
          São aceitas listas de operações exportadas do profit ou utilizando o
          template próprio da plataforma, que pode ser baixado{" "}
          <Button
            className="p-0"
            type="link"
            href="/templates/importar_operacoes.csv"
          >
            aqui.
          </Button>
        </Typography.Paragraph>
        <Row className="flex justify-between w-full">
          <Select
            onChange={(value) => setFileFormat(value)}
            placeholder="Formato do arquivo utilizado"
            className=" min-w-32"
          >
            <Select.Option value={FileFormats.PROFIT}>Profit</Select.Option>
            <Select.Option value={FileFormats.Fiainvest}>
              Fiainvest
            </Select.Option>
          </Select>
          <Select
            onChange={(value) => setNumberFormat(value)}
            placeholder="Formato de números"
            className=" min-w-32"
            value={numberFormat}
          >
            <Select.Option value={NumberFormats.BRAZILIAN}>
              Formato Brasileiro (1.000,00)
            </Select.Option>
            <Select.Option value={NumberFormats.AMERICAN}>
              Formato Americano (1,000.00)
            </Select.Option>
          </Select>
          <Tooltip title="Marca as operações de exercício de opções para serem ignoradas, pois a plataforma já gera automaticamente as mesmas.">
            <Button
              type="default"
              onClick={() => markIgnoreExerciseOperations(parsedRows)}
              disabled={parsedRows.length === 0}
            >
              Ignorar exercícios
            </Button>
          </Tooltip>
          <Upload
            accept=".txt, .csv"
            showUploadList={true}
            beforeUpload={uploadReader}
            multiple={false}
            maxCount={1}
            disabled={fileFormat === null}
          >
            <Button disabled={fileFormat === null} icon={<UploadOutlined />}>
              Escolher arquivo
            </Button>
          </Upload>
        </Row>
        <Row className="mt-4 mb-4 w-full">
          {isDesktop && (
            <Table
              dataSource={parsedRows}
              pagination={{
                pageSize: 8,
                showSizeChanger: false,
                current: selectedPage,
                onChange: (page) => setSelectedPage(page),
              }}
              columns={columns}
              size="small"
              className="w-full"
            />
          )}
          {!isDesktop && (
            <List
              size="small"
              dataSource={parsedRows}
              itemLayout="vertical"
              header="Operações a importar"
              pagination={{
                pageSize: 1,
                current: selectedPage,
                onChange: setSelectedPage,
              }}
              className="w-full"
              renderItem={(item: ParsedRow) => (
                <List.Item>
                  <Form layout="vertical">
                    <Form.Item label={<strong>Ativo</strong>}>
                      <Typography.Text
                        editable={{
                          onChange: (value) => editTicker(item, value),
                        }}
                      >
                        {item.ticker}
                      </Typography.Text>
                    </Form.Item>
                    <Form.Item label={<strong>Data</strong>}>
                      <Typography.Text
                        editable={{
                          onChange: (value) => editDate(item, value),
                        }}
                      >
                        {item.date}
                      </Typography.Text>
                    </Form.Item>
                    <Form.Item label={<strong>Quantidade</strong>}>
                      <Typography.Text
                        editable={{
                          onChange: (value) => editAmount(item, value),
                        }}
                      >
                        {item.amount}
                      </Typography.Text>
                    </Form.Item>
                    <Form.Item label={<strong>Preço</strong>}>
                      <Typography.Text
                        editable={{
                          onChange: (value) => editPrice(item, value),
                        }}
                      >
                        {item.price}
                      </Typography.Text>
                    </Form.Item>
                    <Form.Item label={<strong>Status</strong>}>
                      {renderPreValidationMessage(item)}
                    </Form.Item>
                    <Form.Item label={<strong>Operação nova?</strong>}>
                      {renderExistingOperation(item.existingOperation)}
                    </Form.Item>
                    <Form.Item label={<strong>Carteira</strong>}>
                      {renderWalletSelection(item.wallet, item)}
                    </Form.Item>
                    <Form.Item label={<strong>Ação</strong>}>
                      {renderActionSelection(item.action, item)}
                    </Form.Item>
                  </Form>
                </List.Item>
              )}
            />
          )}
        </Row>
        <Row className="flex justify-end">
          <Button
            type="primary"
            onClick={() => validationMutation.mutateAsync(parsedRows)}
            disabled={validationMutation.isPending}
            loading={validationMutation.isPending}
          >
            Validar
          </Button>
          <Button
            className="ml-2"
            type="primary"
            onClick={() => importMutation.mutateAsync(parsedRows)}
            disabled={
              !isValidated ||
              importCount === 0 ||
              validationMutation.isPending ||
              importMutation.isPending
            }
            loading={importMutation.isPending}
          >
            Importar ({importCount})
          </Button>
        </Row>
      </Col>
    </CollapsibleCard>
  );
};

export default OperationsImport;
