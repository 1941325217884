import React, { createContext, useContext, useEffect, useState } from "react";
import { useQuery } from "@tanstack/react-query";
import { User } from "../api/models";
import { JSONResponse } from "../api/types";
import { message } from "antd";
import { useMutation } from "@tanstack/react-query";
import localStorageService from "../services/localStorage";
import { appApi } from "../api";
import { LoginResponse } from "../api/models";
import { useNavigate } from "react-router-dom";

export type LoginRequestData = {
  email: string;
  password: string;
};

interface AuthContextType {
  isLoggedIn: boolean;
  isLoading: boolean;
  isAdmin: boolean;
  hasAIAccess: boolean;
  hasOptionsAccess: boolean;
  login: ({ email, password }: LoginRequestData) => void;
  logout: () => void;
  performLogin: (data: LoginResponse) => void;
}

const AuthContext = createContext<AuthContextType | undefined>(undefined);

export const AuthProvider = ({ children }: { children: React.ReactNode }) => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);
  const [isAdmin, setIsAdmin] = useState<boolean>(false);
  const [hasAIAccess, setHasAIAccess] = useState<boolean>(false);
  const [hasOptionsAccess, setHasOptionsAccess] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const navigate = useNavigate();

  const { data: userData } = useQuery<JSONResponse<User>>({
    queryKey: ["user"],
    queryFn: () => appApi.authentication.refreshUser(),
    refetchInterval: 180000,
    retry: false,
    throwOnError: false,
    enabled: isLoggedIn,
  });

  useEffect(() => {
    if (userData) {
      localStorageService.saveUser(userData);
      setUserPermissions(userData);
    }
  }, [userData]);

  useEffect(() => {
    const token = localStorageService.getToken();
    if (token) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
    setIsLoading(false);
  }, []);

  const setUserPermissions = (user: User) => {
    if (user && user.isStaff) {
      setIsAdmin(true);
    } else {
      setIsAdmin(false);
    }
    if (
      user &&
      user.userPermissions &&
      user.userPermissions.includes("modulo_opcoes")
    ) {
      setHasOptionsAccess(true);
    } else {
      setHasOptionsAccess(false);
    }
    if (
      user &&
      user.userPermissions &&
      user.userPermissions.includes("modulo_machine_learning")
    ) {
      setHasAIAccess(true);
    } else {
      setHasAIAccess(false);
    }
  };

  useEffect(() => {
    const user = localStorageService.getUser();
    if (user) {
      setUserPermissions(user);
    }
  }, [isLoggedIn]);

  const loginMutation = useMutation({
    mutationFn: ({ email, password }: LoginRequestData) => {
      return appApi.authentication.login({ email, password });
    },
    onSuccess: (data: LoginResponse) => {
      performLogin(data);
    },
    onError: () => {
      message.error(
        "Erro ao fazer login, por favor verifique os dados informados.",
      );
    },
  });

  const performLogin = (data: LoginResponse) => {
    localStorageService.saveToken(data.access);
    localStorageService.saveUser(data.user);
    navigate("/dashboard");
    setIsLoggedIn(true);
  };

  const logoutMutation = useMutation({
    mutationFn: () => {
      setIsLoggedIn(false);
      navigate("/");
      localStorageService.removeUser();
      localStorageService.removeToken();
      return appApi.authentication.logout();
    },
    onError: (error) => {
      console.error("Erro ao fazer logout: ", error);
    },
  });

  const login = ({ email, password }: LoginRequestData) => {
    loginMutation.mutateAsync({ email, password });
  };

  const logout = () => {
    logoutMutation.mutateAsync();
  };

  return (
    <AuthContext.Provider
      value={{
        isLoggedIn,
        isLoading,
        isAdmin,
        hasAIAccess,
        hasOptionsAccess,
        login,
        logout,
        performLogin,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};

export default AuthProvider;
