import "chart.js/auto";
import { IncomeChartData } from "../../api/models";
import { appApi } from "../../api";
import { Alert } from "antd";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Line } from "react-chartjs-2";
import { useQuery } from "@tanstack/react-query";
import CollapsibleCard from "../../components/collapsibleCard";
import { useEffect } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

const IncomeChart = () => {
  const { data, isLoading } = useQuery<IncomeChartData[]>({
    queryKey: ["incomeChart"],
    queryFn: () => appApi.pnl.getIncomeChartData(),
  });

  // subscribing to the visibility changed event and re-rendering the chart whenever the tab transitions to visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const chart = ChartJS.getChart("incomeChart");
        if (chart) {
          chart.update();
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const options = {
    responsive: true,
    plugins: {
      title: {
        display: false,
      },
    },
  };
  const chartData = {
    labels: data ? data.map((item) => item.date) : [],
    datasets: [
      {
        label: "Renda total",
        data: data ? data.map((item) => item.income) : [],
        backgroundColor: "#eb34eb",
        color: "#eb34eb",
        borderColor: "#eb34eb",
      },
      {
        label: "Renda média ult 6 meses",
        data: data ? data.map((item) => item.sixMonthsAverage) : [],
        backgroundColor: "#eb3434",
        color: "#eb3434",
        borderColor: "#eb3434",
      },
      {
        label: "Renda com opções",
        data: data ? data.map((item) => item.details.optionsIncome) : [],
        backgroundColor: "#34eb8f",
        color: "#34eb8f",
        borderColor: "#34eb8f",
      },
      {
        label: "Renda com dividendos",
        data: data ? data.map((item) => item.details.dividendsIncome) : [],
        backgroundColor: "#34c3eb",
        color: "#34c3eb",
        borderColor: "#34c3eb",
      },
      {
        label: "Renda com mini índice",
        data: data ? data.map((item) => item.details.miniIndexIncome) : [],
        backgroundColor: "#ebb434",
        color: "#ebb434",
        borderColor: "#ebb434",
      },
    ],
  };

  return (
    <CollapsibleCard title={"Renda"} loading={isLoading}>
      {(!data && !isLoading) || data?.length === 0 ? (
        <Alert
          type="warning"
          message="Você ainda não possui nenhum resultado de PNL registrado."
        />
      ) : (
        <Line data={chartData} options={options} id="incomeChart" />
      )}
    </CollapsibleCard>
  );
};

export default IncomeChart;
