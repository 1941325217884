import { Col, Row, Button } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import type { ListResponse } from "../../../../api/types";
import { CurrentPosition, WalletCodes } from "../../../../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../../../api";
import NewOperationForm from "../../../../components/newOperation";
import DividePositionForm from "./dividePositionForm";
import { WalletNames } from "../../../../api/models";
import TickerDetailsLink from "../../../../components/tickerDetailsLink";
import ResponsiveTable from "../../../../components/ResponsiveTable";
import CollapsibleCard from "../../../../components/collapsibleCard";
import { Link } from "react-router-dom";
import PriceDisplay from "../../../../components/priceDisplay";
import OptionDetails from "../../../../components/optionDetails";
import NewOperationModal from "../../../../components/newOperationModal";
import TableStringFilter from "../../../../components/tableStringFilter";
import PriceDetails from "../../../../components/priceDetails";
import ChangeWalletForm from "./changeWalletForm";

const getWalletLink = (id: string, code: WalletCodes) => {
  if (
    [
      WalletCodes.ACOES,
      WalletCodes.FIIS,
      WalletCodes.INTERNACIONAL,
      WalletCodes.OUTROS,
    ].includes(code)
  ) {
    return `/value-wallets?tab=${id}`;
  }
  return `/option-wallets?tab=${id}`;
};

const CurrentPositionsTab = () => {
  const [page, setPage] = useState(1);
  const [downloadingPositions, setDownloadingPositions] = useState(false);
  const [selectedPositionForOperation, setSelectedPositionForOperation] =
    useState<CurrentPosition | null>(null);

  const [filteredTicker, setFilteredTicker] = useState<string | null>(null);
  const [sortedColumn, setSortedColumn] = useState<string | null>(null);
  const [sortedOrder, setSortedOrder] = useState<string | null>(null);

  const positionsKey = [
    "currentPositions",
    page,
    filteredTicker,
    sortedColumn,
    sortedOrder,
  ];
  const { data, isLoading, isRefetching } = useQuery<
    ListResponse<CurrentPosition>
  >({
    queryKey: positionsKey,
    queryFn: () =>
      appApi.currentPositions.getList(
        page,
        filteredTicker,
        10,
        sortedColumn,
        sortedOrder,
      ),
  });

  const exportCurrentPositions = async () => {
    setDownloadingPositions(true);
    const response = await appApi.currentPositions.getList(1, undefined, 1000);
    const positions = response.results;
    const csvContent =
      "data:text/csv;charset=utf-8," +
      "ativo;quantidade;preco_medio\n" +
      positions
        .map(
          (position) =>
            `${position.ticker};${position.amount};${position.averagePrice.replace(".", ",")}`,
        )
        .join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", "posicoes.csv");
    document.body.appendChild(link);
    setDownloadingPositions(false);
    link.click();
  };

  const columns = [
    {
      title: "Ticker",
      dataIndex: "ticker",
      key: "ticker",
      render: (ticker: string, record: CurrentPosition) => (
        <div className="flex flex-row">
          <TickerDetailsLink ticker={ticker} />
          <Button
            icon={<EditOutlined style={{ color: "blue" }} />}
            size="small"
            type="text"
            style={{ marginLeft: 8 }}
            onClick={() => setSelectedPositionForOperation(record)}
          />
        </div>
      ),
    },
    {
      title: "Quantidade",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Preço Últ. negócio",
      dataIndex: "lastPrice",
      key: "lastPrice",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "Preço Atual",
      dataIndex: "lastMarketPrice",
      key: "lastMarketPrice",
      render: (_: string, position: CurrentPosition) => (
        <PriceDetails
          price={position.lastMarketPrice}
          priceAsk={position.lastMarketPriceAsk}
          priceBid={position.lastMarketPriceBid}
          priceStatus={position.lastMarketPriceStatus}
          liquidityStatus={position.lastMarketPriceLiquidityStatus}
          priceDate={position.lastMarketPriceDate}
          showAsTooltip={true}
        />
      ),
    },
    {
      title: "Total da posição",
      dataIndex: "positionTotal",
      key: "positionTotal",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "Delta da opção",
      dataIndex: "optionDeltaDisplay",
      key: "optionDeltaDisplay",
      render: (value: string, record: CurrentPosition) => {
        return (
          <div>
            <span className="mr-2">{value}</span>
            <OptionDetails position={record} showAsTooltip />
          </div>
        );
      },
    },
    {
      title: "Carteira",
      dataIndex: "walletName",
      key: "walletName",
      editable: true,
      inputType: "select",
      selectOptions: Object.values(WalletNames),
      width: "15%",
      render: (value: string, record: CurrentPosition) => (
        <Link
          to={getWalletLink(
            record.walletId as string,
            record.walletCode as WalletCodes,
          )}
        >
          {value}
        </Link>
      ),
    },
    {
      title: "Dividir",
      dataIndex: "id",
      render: (_: any, record: CurrentPosition) => (
        <DividePositionForm currentPosition={record} />
      ),
    },
    {
      title: "Alterar carteira",
      dataIndex: "id",
      render: (_: any, record: CurrentPosition) => (
        <ChangeWalletForm currentPosition={record} />
      ),
    },
  ];

  const setFilter = (value: string | null) => {
    setFilteredTicker(value);
    setPage(1);
  };

  const handleTableChange = (_: any, __: any, sorter: any) => {
    if (sorter) {
      setSortedColumn(sorter.field);
      setSortedOrder(sorter?.order === "ascend" ? "asc" : "desc");
    }
  };

  return (
    <Row>
      <Col sm={24} md={6} className="w-full">
        <NewOperationForm />
      </Col>
      <Col sm={24} md={18} className="w-full">
        {selectedPositionForOperation && (
          <NewOperationModal
            title={`Nova operação sobre o ativo ${selectedPositionForOperation.ticker}`}
            ticker={selectedPositionForOperation.ticker}
            amount={selectedPositionForOperation.amount * -1}
            price={parseFloat(selectedPositionForOperation.lastMarketPrice)}
            walletName={selectedPositionForOperation.walletName as WalletNames}
            visible={!!selectedPositionForOperation}
            onComplete={() => setSelectedPositionForOperation(null)}
          />
        )}
        <CollapsibleCard
          title="Posições atuais"
          action={
            <Button
              onClick={() => exportCurrentPositions()}
              disabled={downloadingPositions || isLoading}
              loading={downloadingPositions}
            >
              Exportar posições
            </Button>
          }
        >
          <ResponsiveTable
            size="small"
            columns={columns}
            dataSource={data?.results}
            rowClassName="editable-row"
            title={() => (
              <TableStringFilter label="Ativo" setFilter={setFilter} />
            )}
            loading={isLoading || isRefetching}
            pagination={{
              current: page,
              onChange: (page) => {
                setPage(page);
              },
              total: data?.count,
              pageSize: 10,
              showSizeChanger: false,
            }}
            rowKey="id"
            onChange={handleTableChange}
          />
        </CollapsibleCard>
      </Col>
    </Row>
  );
};

export default CurrentPositionsTab;
