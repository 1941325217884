import { Spin, Tabs } from "antd";
import type { TabsProps } from "antd";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { SimplifiedWallet, WalletCodes } from "../../../api/models";
import ValueWalletTemplate from "./valueWalletTemplate";
import { appApi } from "../../../api";
import { EditWalletModal } from "../editWalletModal";

type WalletDescription = {
  title: string | undefined;
  description: React.ReactNode | undefined;
};

const returnWalletDescriptionOne = (
  walletCode: WalletCodes,
  walletAutomationAlgorithm: string | null,
) => {
  if (
    walletCode == WalletCodes.ACOES &&
    walletAutomationAlgorithm === "ML_WALLET"
  ) {
    return {
      title: "O que fazer",
      description: (
        <>
          <p>
            - Comprar ações no topo do ranking e diversificadas entre clusters
          </p>
          <p>
            - Vender ação que aparecer acima do percentil 80% no ranking,
            trocando por ações no topo do ranking diversificadas entre clusters
          </p>
        </>
      ),
    };
  }
  if (
    walletCode == WalletCodes.ACOES &&
    walletAutomationAlgorithm !== "ML_WALLET"
  ) {
    return {
      title: "O que fazer",
      description: (
        <>
          <p>
            <span style={{ fontWeight: "bold" }}>Montar Carteira de Ações</span>
            : Definir Delta Carteira de Ações - de 10 a 25 papeis em setores
            diferentes. Nenhuma ação representa mais de 8% da carteira
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Comprar ações (lastro)</span>:
            Ações para ficar de herança! Compre bons negócios nunca pagando caro
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Aproveite as "crises"</span>:
            IBOV -15% | -25% | -35% | -45% da máxima de 3 meses
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Mexa pouco nas suas ações:
            </span>{" "}
            6 meses a 1 ano.
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>Colecione ações:</span> Foco
            deve ser em ter o maior número possível de ações das empresas
          </p>
          <p>
            <span style={{ fontWeight: "bold" }}>
              Coloque ações em margem / garantia na B3
            </span>{" "}
            para operar opções
          </p>
        </>
      ),
    };
  }
  return {
    title: undefined,
    description: undefined,
  };
};

const returnWalletDescriptionTwo = (walletCode: WalletCodes) => {
  if (walletCode == WalletCodes.INTERNACIONAL) {
    return {
      title: "Boas práticas",
      description: (
        <>
          <p>Realizar a utilização de ETFs para diversificação de carteira.</p>
          <p>
            A carteira internacional deve servir como Hedge cambial que trás
            equilíbrio para a carteira.
          </p>
        </>
      ),
    };
  }
  if ([WalletCodes.FIIS].includes(walletCode)) {
    return {
      title: "Boas práticas",
      description: (
        <>
          <p>Ideal para criar renda mais estável, previsível e recorrente.</p>
          <p>
            Diversifique entre vários fundos de várias gestoras para mitigar o
            risco de gestão dos fundos.
          </p>
          <p>Evite fundos monoativos.</p>
        </>
      ),
    };
  }
  if (walletCode == WalletCodes.OUTROS) {
    return {
      title: "Orientações",
      description: (
        <>
          <p>Carteira para agrupamento de estratégias diversas.</p>
        </>
      ),
    };
  }
  return {
    title: undefined,
    description: undefined,
  };
};

const WalletPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data, isLoading } = useQuery<SimplifiedWallet[]>({
    queryKey: ["wallets", "simplified", "stock"],
    queryFn: () => appApi.wallets.getSimplifiedWalletsList("stock"),
  });

  const wallets = data || [];

  if (isLoading) {
    return <Spin />;
  }

  const firstWalletsId = wallets && wallets.length > 0 ? wallets[0].id : "";

  const tab = searchParams.get("tab") || firstWalletsId;
  const items: TabsProps["items"] = wallets.map((wallet) => {
    const descriptionOne: WalletDescription = returnWalletDescriptionOne(
      wallet.code as WalletCodes,
      wallet.walletAutomationAlgorithm,
    );
    const descriptionTwo: WalletDescription = returnWalletDescriptionTwo(
      wallet.code as WalletCodes,
    );
    return {
      key: wallet.id,
      label: (
        <div className="flex justify-between flex-row items-center">
          <div>{wallet.name}</div>
          <EditWalletModal
            walletId={wallet.id}
            walletName={wallet.name}
            walletCode={wallet.code}
            walletAutomationAlgorithm={wallet.walletAutomationAlgorithm}
          />
        </div>
      ),
      children: (
        <ValueWalletTemplate
          name={wallet.name}
          title={wallet.name}
          code={wallet.code as WalletCodes}
          key={wallet.id}
          walletId={wallet.id}
          descriptionOneTitle={descriptionOne.title}
          descriptionOne={descriptionOne.description}
          descriptionTwoTitle={descriptionTwo.title}
          descriptionTwo={descriptionTwo.description}
        />
      ),
    };
  });

  let selectedTab = items.find((item) => item.key === tab)
    ? tab
    : firstWalletsId;

  return (
    <Tabs
      defaultActiveKey={selectedTab}
      activeKey={selectedTab}
      items={items}
      onChange={(activeKey) => setSearchParams({ tab: activeKey })}
      tabBarExtraContent={<EditWalletModal />}
    />
  );
};

export default WalletPage;
