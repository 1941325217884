import { useEffect, useState } from "react";
import type { ListResponse } from "../../api/types";
import { AccountMonthlyResult, WalletOverrides } from "../../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../api";
import { useSearchParams } from "react-router-dom";
import { Button, Form, Input } from "antd";
import PNLRecords from "./pnlDataTable";
import { EditPnlRecord } from "./editPnlRecord";
import CollapsibleCard from "../../components/collapsibleCard";

const MonthlyPNLRecords = () => {
  const queryKey = "monthlyPNLRecords";
  const [searchParams, setSearchParams] = useSearchParams();
  const year = searchParams.get("year");

  const [page, setPage] = useState(1);
  const { data, isFetching } = useQuery<ListResponse<AccountMonthlyResult>>({
    queryKey: [queryKey, page, year],
    queryFn: () => appApi.pnl.getAccountMonthlyPNL(page, year),
  });
  const [form] = Form.useForm();

  useEffect(() => {
    if (year) {
      form.setFieldsValue({ year });
    }
  }, [year, form]);

  const columns = [
    {
      title: "Mês",
      dataIndex: "month",
      key: "month",
      render: (_: string, record: AccountMonthlyResult) => (
        <Button
          type="link"
          onClick={() =>
            setSearchParams({
              period: "daily",
              year: record.year,
              month: record.month,
            })
          }
        >
          {record.year}-{record.month}
        </Button>
      ),
    },
  ];

  const afterColumns = [
    {
      title: "Editar",
      dataIndex: "id",
      render: (_: string, record: AccountMonthlyResult) => {
        const walletOverrides: WalletOverrides[] = record.wallets.map(
          (result) => ({
            name: result.name,
            pnl: result.overridenPnlResult,
          }),
        );
        return (
          <EditPnlRecord
            year={Number(record.year)}
            month={Number(record.month)}
            walletOverrides={walletOverrides}
            mode="monthly"
          />
        );
      },
    },
  ];

  return (
    <CollapsibleCard
      title="Registros de PNL Mensal"
      bordered={true}
      collapsible={false}
      action={<EditPnlRecord mode="monthly" />}
    >
      <Form
        form={form}
        layout="inline"
        className="mb-3"
        initialValues={{ year }}
      >
        <Form.Item label="Ano" name="year">
          <Input type="number" required />
        </Form.Item>
        <Form.Item>
          <Button
            type="primary"
            onClick={() =>
              setSearchParams({
                period: "monthly",
                year: form.getFieldValue("year"),
              })
            }
          >
            Filtrar
          </Button>
        </Form.Item>
        <Form.Item>
          <Button
            onClick={() => {
              setSearchParams({ period: "monthly" });
              form.setFieldValue("year", "");
            }}
          >
            Limpar
          </Button>
        </Form.Item>
      </Form>
      <PNLRecords // @ts-ignore comment
        data={data}
        additionalColumns={columns}
        isFetching={isFetching}
        page={page}
        setPage={setPage}
        columnsAfterWallets={afterColumns}
      />
    </CollapsibleCard>
  );
};

export default MonthlyPNLRecords;
