import { Col, Row, Spin, Tooltip } from "antd";
import React from "react";
import {
  ValueWalletDetails,
  Wallet,
  CurrentPosition,
  WalletNames,
  WalletCodes,
} from "../../../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../../api";
import WalletItemList from "../walletItemsList";
import {
  numberToCurrency,
  numberToDecimal,
  numberToPercentageDisplay,
} from "../../../services/utils";
import ValueWalletAllocationChart from "./allocationChart";
import CollapsibleCard from "../../../components/collapsibleCard";
import PriceDisplay from "../../../components/priceDisplay";
import { translateNumberToLetter } from "../../../services/utils";
import MLClustersAllocationChart from "./mlClustersAllocationChart";
import { InfoCircleOutlined } from "@ant-design/icons";
import MLRankingPurchaseList from "./mlRankingPurchaseList";

const ValueWalletTemplate = ({
  name,
  code,
  walletId,
  title,
  descriptionOneTitle,
  descriptionOne,
  descriptionTwoTitle,
  descriptionTwo,
}: {
  name: string;
  code: WalletCodes;
  title: string;
  walletId: string;
  descriptionOneTitle?: string;
  descriptionOne?: React.ReactNode;
  descriptionTwoTitle?: string;
  descriptionTwo?: React.ReactNode;
}) => {
  const { data, isLoading } = useQuery<Wallet>({
    queryKey: ["wallets", walletId],
    queryFn: () => appApi.wallets.get(walletId),
  });

  if (isLoading) {
    return <Spin />;
  }

  let positions: CurrentPosition[] = [];
  const wallet = data;
  if (wallet) {
    positions = wallet.positions;
  }

  const walletDetails: ValueWalletDetails | undefined = wallet?.walletDetails;

  const walletTotal = positions.reduce(
    (accumulatedResult, item) =>
      accumulatedResult + parseFloat(item.positionTotal),
    0,
  );
  let additionalColumns = [
    {
      title: "Total da Posição",
      dataIndex: "positionTotal",
      key: "positionTotal",
      render: (value: string, _: CurrentPosition) => (
        <PriceDisplay price={value} />
      ),
      sorter: (a: CurrentPosition, b: CurrentPosition) => {
        return parseFloat(a.positionTotal) - parseFloat(b.positionTotal);
      },
    },
    {
      title: "Percentual da carteira",
      dataIndex: "positionTotal",
      key: "positionTotal",
      render: (value: string) => {
        const positionTotal = parseFloat(value);
        const percentage = ((positionTotal / walletTotal) * 100).toFixed(2);
        return `${percentage}%`;
      },
      sorter: (a: CurrentPosition, b: CurrentPosition) => {
        return parseFloat(a.positionTotal) - parseFloat(b.positionTotal);
      },
    },
  ];

  const isMLWallet = wallet?.walletAutomationAlgorithm === "ML_WALLET";
  if (isMLWallet) {
    additionalColumns = [
      ...additionalColumns,
      {
        title: "Machine Learning",
        dataIndex: "mlPercentile",
        key: "mlPercentile",
        render: (value: string, record: CurrentPosition) => {
          const tooltipContent = (
            <>
              <h3>Detalhes do Ranking</h3>
              <p>Ranking: {record.mlRanking}</p>
              <p>
                Cluster:{" "}
                {translateNumberToLetter(parseFloat(record.mlCluster || "0"))}
              </p>
              <p>Percentil: {numberToPercentageDisplay(value)}</p>
            </>
          );
          if (!value) {
            return <span>N/D</span>;
          }
          return (
            <Tooltip title={tooltipContent}>
              {numberToPercentageDisplay(value)}
              <InfoCircleOutlined className="ml-1" />
            </Tooltip>
          );
        },
        sorter: (a: CurrentPosition, b: CurrentPosition) => {
          return (a.mlPercentile || 0) - (b.mlPercentile || 0);
        },
      },
    ];
  }

  return (
    <Row className="w-full">
      <Col xs={24} sm={24} md={12} lg={12} xl={12} className="w-full">
        {wallet?.walletAutomationAlgorithm === "ML_WALLET" && (
          <Row className="w-full">
            <MLRankingPurchaseList walletId={wallet.id} />
          </Row>
        )}
        <Row className="w-full">
          <Col xs={24} className="w-full">
            <CollapsibleCard title="Alocação por ativo">
              <ValueWalletAllocationChart positions={positions} />
            </CollapsibleCard>
          </Col>
        </Row>
        {descriptionOneTitle && descriptionOne && (
          <Row className="w-full">
            <CollapsibleCard title={descriptionOneTitle}>
              {descriptionOne}
            </CollapsibleCard>
          </Row>
        )}
      </Col>
      <Col xs={24} sm={24} md={12} lg={12} xl={12} className="w-full">
        <Row className="w-full">
          <Col xl={24} className="w-full">
            {wallet && (
              <WalletItemList
                positions={positions}
                isLoading={isLoading}
                walletName={name as WalletNames}
                walletId={wallet?.id}
                additionalColumns={additionalColumns}
                displayWalletStrategy={false}
                title={
                  <div className="flex flex-row justify-between">
                    <div className="mr-4">{title}</div>
                    <div>
                      Delta: {"  "}
                      {walletDetails && walletDetails.totalValue
                        ? numberToCurrency(walletDetails?.totalValue)
                        : positions.length > 0
                          ? "Calculando..."
                          : "R$ 0,00"}
                    </div>
                  </div>
                }
              />
            )}
            {descriptionTwoTitle && descriptionTwo && (
              <Row className="w-full">
                <CollapsibleCard title={descriptionTwoTitle}>
                  {descriptionTwo}
                </CollapsibleCard>
              </Row>
            )}
            {code === WalletCodes.ACOES && (
              <Row className="w-full">
                <CollapsibleCard title="Detalhes da carteira de ações">
                  <p>
                    <span style={{ fontWeight: "bold" }}>Valor total:</span>{" "}
                    {walletDetails?.totalValue
                      ? numberToCurrency(walletDetails?.totalValue)
                      : "Calculando..."}
                  </p>
                  {walletDetails?.beta !== undefined &&
                    walletDetails?.beta !== 0 && (
                      <>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Correlação com o BOVA11:
                          </span>{" "}
                          {numberToDecimal(walletDetails?.correlation)}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Beta da carteira:
                          </span>{" "}
                          {numberToDecimal(walletDetails?.beta)}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Volatilidade da carteira:
                          </span>{" "}
                          {numberToDecimal(walletDetails?.volatility)}
                        </p>
                        <p>
                          <span style={{ fontWeight: "bold" }}>
                            Volatilidade do BOVA11:
                          </span>{" "}
                          {numberToDecimal(
                            walletDetails?.correlatedAssetVolatility,
                          )}
                        </p>
                      </>
                    )}
                  {wallet?.walletAutomationAlgorithm === "ML_WALLET" && (
                    <p>
                      <span style={{ fontWeight: "bold" }}>
                        Algoritmo de automação:
                      </span>{" "}
                      Machine Learning
                    </p>
                  )}
                </CollapsibleCard>
                {wallet?.walletAutomationAlgorithm === "ML_WALLET" && (
                  <CollapsibleCard title="Alocação por cluster">
                    <MLClustersAllocationChart positions={positions} />
                  </CollapsibleCard>
                )}
              </Row>
            )}
          </Col>
        </Row>
      </Col>
    </Row>
  );
};

export default ValueWalletTemplate;
