import { Form, Button, message, Modal } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../../../api";
import { WALLET_QUERY_KEYS } from "../../constants";
import { CurrentPosition, WalletNames } from "../../../../api/models";
import WalletSelection from "../../../../components/walletSelection";

interface FormValues {
  wallet: WalletNames;
}

const ChangeWalletForm = ({
  currentPosition,
}: {
  currentPosition: CurrentPosition;
}) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const positionsKey = ["currentPositions"];
  const [mutationLoading, setMutationLoading] = useState(false);

  const splitPositionMutation = useMutation({
    mutationFn: ({ wallet }: FormValues) => {
      setMutationLoading(true);
      return appApi.currentPositions.update({
        id: currentPosition.id,
        newWalletName: wallet,
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: positionsKey });
      WALLET_QUERY_KEYS.forEach(async (key) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
      setMutationLoading(false);
      setVisible(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  const onFormSubmit = async () => {
    const values = form.getFieldsValue() as FormValues;
    const { wallet } = values;
    console.log("values", values);
    if (wallet === currentPosition.walletName) {
      message.error(
        "A carteira de destino deve ser diferente da carteira atual.",
      );
      return;
    }
    splitPositionMutation.mutateAsync(values);
  };

  return (
    <div>
      <Button type="link" onClick={() => setVisible(true)}>
        Alterar carteira
      </Button>
      <Modal
        title={`Alterar carteira da posição ${currentPosition.ticker}`}
        open={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={() => onFormSubmit()}
        confirmLoading={mutationLoading}
      >
        <Form
          layout="vertical"
          form={form}
          initialValues={{ wallet: currentPosition.walletName }}
        >
          <Form.Item
            name="wallet"
            label="Nova Carteira"
            rules={[
              { required: true, message: "Por favor informe a nova carteira" },
            ]}
          >
            <WalletSelection />
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};

export default ChangeWalletForm;
