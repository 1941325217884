import React from "react";
import * as Sentry from "@sentry/react";
import ReactDOM from "react-dom/client";
import App from "./App.tsx";
import "./App.css";
import {
  createBrowserRouter,
  createRoutesFromChildren,
  RouterProvider,
  matchRoutes,
  useLocation,
  useNavigationType,
} from "react-router-dom";
import PageNotFound from "./pages/404";
import ChangePasswordPage from "./pages/change-password/";
import PasswordResetPage from "./pages/password-reset/";
import LoginPage from "./pages/login/index.tsx";
import DashboardPage from "./pages/dashboard/index.tsx";
import ValueWalletsPage from "./pages/wallet/wallets/index.tsx";
import OptionWalletsPage from "./pages/wallet/optionWallets/index.tsx";
import PositionsPage from "./pages/wallet/positions/index.tsx";
import EditAccountPage from "./pages/edit-account/index.tsx";
import OptionsMonitorPage from "./pages/options-monitor/index.tsx";
import PNLPage from "./pages/pnl/index.tsx";
import IncomePage from "./pages/income/index.tsx";
import PersonalizationPage from "./pages/personalization/index.tsx";
import AssetSearch from "./pages/asset-search/index.tsx";
import BlackScholesCalculator from "./pages/bs-calculator/index.tsx";
import LoginAsUserPage from "./pages/login-as-user/index.tsx";
import SimulationPage from "./pages/simulator/index.tsx";
import RankingAIPage from "./pages/ranking-ai/index.tsx";
import { findErrorMessage } from "./api/request.ts";
import { AxiosError } from "axios";
import { getUser } from "./services/localStorage.ts";

export const loggedOutRoutes = [
  "/",
  "password-reset",
  "login-as-user",
  "options-monitor",
];

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [
    Sentry.reactRouterV6BrowserTracingIntegration({
      useEffect: React.useEffect,
      useLocation,
      useNavigationType,
      createRoutesFromChildren,
      matchRoutes,
    }),
  ],
  tracesSampleRate: 1.0,
  beforeSend(event, hint) {
    const error: any = hint.originalException;
    if (error instanceof AxiosError) {
      if (error.response && [400, 401].includes(error.response.status)) {
        return null;
      }
      if (error.response && error.response.status) {
        event.extra = {
          "api-user-displayed-error-message": findErrorMessage(error),
          "api-response-error": error.toString(),
          "api-response-data": error.response.data,
        };
      }
    }
    const user = getUser();
    if (user) {
      event.user = {
        email: user.email,
      };
    }
    return event;
  },
});

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <PageNotFound />,
    children: [
      { path: "/", element: <LoginPage /> },
      { path: "404", element: <PageNotFound /> },
      { path: "dashboard", element: <DashboardPage /> },
      { path: "pnl", element: <PNLPage /> },
      { path: "income", element: <IncomePage /> },
      { path: "simulator", element: <SimulationPage /> },
      { path: "value-wallets", element: <ValueWalletsPage /> },
      { path: "option-wallets", element: <OptionWalletsPage /> },
      { path: "positions", element: <PositionsPage /> },
      { path: "edit-account", element: <EditAccountPage /> },
      { path: "personalization", element: <PersonalizationPage /> },
      { path: "options-monitor", element: <OptionsMonitorPage /> },
      { path: "asset-search", element: <AssetSearch /> },
      { path: "bs-calculator", element: <BlackScholesCalculator /> },
      { path: "ranking-ai", element: <RankingAIPage /> },
      { path: "login-as-user", element: <LoginAsUserPage /> },
      {
        path: "change-password",
        element: <ChangePasswordPage />,
      },
      {
        path: "password-reset",
        element: <PasswordResetPage />,
      },
    ],
  },
]);

ReactDOM.createRoot(document.getElementById("root")!).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>,
);
