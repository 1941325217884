import { Select, Spin } from "antd";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../api";
import { SimplifiedWallet } from "../api/models";

interface CustomSelectProps {
  value?: string;
  onChange?: (value: string) => void;
  placeholder?: string;
  additionalOptions?: { value: string; label: string }[];
  className?: string;
}

const WalletSelection = ({
  value,
  onChange,
  placeholder,
  additionalOptions,
  className,
}: CustomSelectProps) => {
  const { data } = useQuery<SimplifiedWallet[]>({
    queryKey: ["wallets", "simplified"],
    queryFn: () => appApi.wallets.getSimplifiedWalletsList(null),
  });

  const wallets = data || [];

  return (
    <Select
      value={value}
      onChange={onChange}
      placeholder={placeholder}
      className={className ? className : ""}
    >
      {wallets.length === 0 && (
        <Select.Option value={null} disabled>
          <Spin />
        </Select.Option>
      )}
      {additionalOptions &&
        additionalOptions.map((option) => (
          <Select.Option key={option.value} value={option.value}>
            {option.label}
          </Select.Option>
        ))}
      {wallets.map((wallet) => (
        <Select.Option key={wallet.id} value={wallet.name}>
          {wallet.name}
        </Select.Option>
      ))}
    </Select>
  );
};

export default WalletSelection;
