import { Button, Checkbox, Form, Input, Modal } from "antd";
import { useQuery } from "@tanstack/react-query";
import { useState } from "react";
import { appApi } from "../../../api";
import { DollarOutlined } from "@ant-design/icons";
import {
  translateNumberToLetter,
  numberToPercentageDisplay,
  numberToCurrency,
} from "../../../services/utils";
import CollapsibleCard from "../../../components/collapsibleCard";
import ResponsiveTable from "../../../components/ResponsiveTable";

const columns = [
  {
    title: "Ativo",
    dataIndex: "ticker",
    key: "ticker",
  },
  {
    title: "Ranking",
    dataIndex: "predictedrank",
    key: "predictedrank",
  },
  {
    title: "Percentil",
    dataIndex: "predictedpercentil",
    key: "predictedpercentil",
    render: (value: number) => numberToPercentageDisplay(value),
  },
  {
    title: "Cluster",
    dataIndex: "cluster",
    key: "cluster",
    render: (value: number) => translateNumberToLetter(value),
  },
];

const WalletPurchasesModal = ({ walletId }: { walletId: string }) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [purchaseAmount, setPurchaseAmount] = useState(0);
  const [ignoredTickers, setIgnoredTickers] = useState<string[]>([]);

  const { data, isLoading } = useQuery({
    queryKey: [
      "ai-purchase-list-with-amounts",
      walletId,
      purchaseAmount,
      ignoredTickers,
    ],
    queryFn: () =>
      appApi.pluriiAI.aiPurchaseRankingWithAmounts(
        walletId,
        purchaseAmount,
        ignoredTickers,
      ),
    enabled: purchaseAmount > 0,
  });
  const [form] = Form.useForm();

  const records = data ? data : [];

  const columns = [
    {
      title: "Ignorar",
      dataIndex: "ticker",
      key: "ticker",
      render: (value: string) => (
        <Checkbox
          onChange={(event) => {
            if (!event.target.checked) {
              setIgnoredTickers(
                ignoredTickers.filter((ticker) => ticker !== value),
              );
              return;
            } else {
              setIgnoredTickers([...ignoredTickers, value]);
            }
          }}
          checked={ignoredTickers.includes(value)}
        />
      ),
    },
    {
      title: "Ativo",
      dataIndex: "ticker",
      key: "ticker",
    },
    {
      title: "Preço de mercado",
      dataIndex: "marketPrice",
      key: "marketPrice",
      render: (value: number) => numberToCurrency(value),
    },
    {
      title: "Quantidade",
      dataIndex: "quantity",
      key: "quantity",
    },
    {
      title: "Valor em R$",
      dataIndex: "cashAmount",
      key: "cashAmount",
      render: (value: number) => numberToCurrency(value),
    },
  ];

  return (
    <>
      <Button
        type="default"
        onClick={() => setIsModalVisible(true)}
        icon={<DollarOutlined />}
      >
        Calcular aporte
      </Button>
      <Modal
        title="Aporte de capital"
        open={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        onOk={() => setIsModalVisible(false)}
      >
        <Form form={form} layout="inline">
          <Form.Item label="Valor do aporte" name="purchaseAmount">
            <Input type="number" min={0} prefix="R$" />
          </Form.Item>
          <Button
            type="primary"
            onClick={() =>
              setPurchaseAmount(form.getFieldValue("purchaseAmount"))
            }
          >
            Calcular
          </Button>
        </Form>
        <ResponsiveTable
          rowKey={(record) => record.ticker}
          loading={isLoading}
          columns={columns}
          dataSource={records}
        />
      </Modal>
    </>
  );
};

const MLRankingPurchaseList = ({ walletId }: { walletId: string }) => {
  const { data, isLoading } = useQuery({
    queryKey: ["ai-purchase-list", walletId],
    queryFn: () => appApi.pluriiAI.aiPurchaseRanking(walletId),
  });

  const records = data ? data : [];

  return (
    <CollapsibleCard
      title="Topo do ranking para compra"
      action={<WalletPurchasesModal walletId={walletId} />}
    >
      <p>
        Estas ações já respeitam os filtros pré-definidos em suas preferências
      </p>
      <ResponsiveTable
        rowKey={(record) => record.ticker}
        loading={isLoading}
        columns={columns}
        dataSource={records}
      />
    </CollapsibleCard>
  );
};

export default MLRankingPurchaseList;
