import { useEffect, useState } from "react";
import type { ListResponse } from "../../../api/types";
import { AccountDailyResult, WalletOverrides } from "../../../api/models";
import { useQuery } from "@tanstack/react-query";
import { Tooltip, message } from "antd";
import { appApi } from "../../../api";
import { useSearchParams } from "react-router-dom";
import { Button, Form, Input } from "antd";
import PNLRecords from "../pnlDataTable";
import CollapsibleCard from "../../../components/collapsibleCard";
import {
  CloseOutlined,
  CheckOutlined,
  InfoCircleFilled,
  RedoOutlined,
} from "@ant-design/icons";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { formatDate } from "../../../services/dates";
import {
  newDateMinusDays,
  createNewDateFromString,
} from "../../../services/dates";
import { EditPnlRecord } from "../editPnlRecord";

const DailyPNLRecords = () => {
  const queryKey = "dailyPNLRecords";
  const queryClient = useQueryClient();
  const [searchParams, setSearchParams] = useSearchParams();
  const year = searchParams.get("year");
  const month = searchParams.get("month");
  const date = searchParams.get("date");
  const [mutationLoading, setMutationLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [refetchInterval, setRefreshInterval] = useState<number | undefined>(
    120000,
  );
  const [accountCalculationsInProgress, setAccountCalculationsInProgress] =
    useState<boolean>(false);

  const { data, isFetching } = useQuery<ListResponse<AccountDailyResult>>({
    queryKey: [queryKey, page, year, month],
    queryFn: () => appApi.pnl.getAccountDailyPNL(page, year, month),
    refetchInterval: refetchInterval,
  });

  useEffect(() => {
    const hasRecordsCalculating = data?.results?.some(
      (record) => record.isPendingProcessing,
    );
    if (hasRecordsCalculating) {
      setRefreshInterval(20000);
      setAccountCalculationsInProgress(true);
    } else {
      setRefreshInterval(360000);
      if (accountCalculationsInProgress) {
        setAccountCalculationsInProgress(false);
        message.success("Recálculo de PNL finalizado.");
      }
    }
  }, [data]);

  const refreshQueries = async () => {
    queryClient.refetchQueries({ queryKey: [queryKey, page, year, month] });
    queryClient.invalidateQueries({ queryKey: ["monthlyPNLRecords"] });
    queryClient.invalidateQueries({ queryKey: ["yearlyPNLRecords"] });
    queryClient.invalidateQueries({ queryKey: ["pnlChartData"] });
  };

  const { mutateAsync: ignoreRecords } = useMutation({
    mutationFn: ({ id }: AccountDailyResult) => {
      setMutationLoading(true);
      return appApi.pnl.ignoreRecord(id);
    },
    onSuccess: async (response: AccountDailyResult) => {
      const messageDisplayed = `PNL da data ${formatDate(response.date)} será ignorado dos resultados mensais.`;
      message.success(messageDisplayed);
      refreshQueries();
      setMutationLoading(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  const { mutateAsync: unignoreRecords } = useMutation({
    mutationFn: ({ id }: AccountDailyResult) => {
      setMutationLoading(true);
      return appApi.pnl.unignoreRecord(id);
    },
    onSuccess: async (response: AccountDailyResult) => {
      const messageDisplayed = `PNL da data ${formatDate(response.date)} voltou a ser considerado nos resultados mensais.`;
      message.success(messageDisplayed);
      refreshQueries();
      setMutationLoading(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  const { mutateAsync: recalculateResult } = useMutation({
    mutationFn: ({ id }: AccountDailyResult) => {
      setMutationLoading(true);
      return appApi.pnl.recalculateAccountResult(id);
    },
    onSuccess: async () => {
      refreshQueries();
      setMutationLoading(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  const [form] = Form.useForm();

  const columns = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      render: (value: string, record: AccountDailyResult) => (
        <>
          <Tooltip title="Recalcular resultados a partir deste dia">
            <Button
              type="text"
              onClick={() => recalculateResult(record)}
              disabled={
                mutationLoading ||
                record.resultIgnored ||
                record.isPendingProcessing ||
                createNewDateFromString(record.date) <= newDateMinusDays(30)
              }
              icon={<RedoOutlined />}
              loading={mutationLoading || record.isPendingProcessing}
            />
          </Tooltip>
          <Tooltip title="Ver detalhes do resultado">
            <Button
              type="link"
              onClick={() =>
                setSearchParams({
                  period: "daily",
                  date: value,
                })
              }
            >
              {value}
            </Button>
          </Tooltip>
        </>
      ),
    },
    {
      title: (
        <Tooltip title="Indica se o resultado do dia está sendo considerado no cálculo de PNL da conta">
          Ignorado
          <InfoCircleFilled className="ml-2" />
        </Tooltip>
      ),
      dataIndex: "resultIgnored",
      render: (value: boolean, record: AccountDailyResult) => {
        return value ? (
          <div className="flex justify-center" key={record.id}>
            Sim
            <Tooltip title="Voltar a considerar o resultado no cálculo de PNL">
              <Button
                type="text"
                icon={<CheckOutlined />}
                disabled={mutationLoading}
                onClick={() => unignoreRecords(record)}
                size="small"
                className="ml-2"
              />
            </Tooltip>
          </div>
        ) : (
          <div className="flex justify-center">
            Não
            <Tooltip title="Ignorar o resultado no cálculo de PNL mensal / anual">
              <Button
                type="text"
                icon={<CloseOutlined />}
                disabled={mutationLoading}
                onClick={() => ignoreRecords(record)}
                size="small"
                className="ml-2"
              />
            </Tooltip>
          </div>
        );
      },
    },
  ];

  const afterColumns = [
    {
      title: "Editar",
      dataIndex: "id",
      render: (_: string, record: AccountDailyResult) => {
        const walletOverrides: WalletOverrides[] = record.wallets.map(
          (result) => ({
            name: result.name,
            pnl: result.overridenPnlResult,
          }),
        );
        return (
          <EditPnlRecord
            date={record.date}
            walletOverrides={walletOverrides}
            mode="daily"
          />
        );
      },
    },
  ];

  useEffect(() => {
    if (year && month) {
      form.setFieldsValue({ year, month, date });
    }
  }, [year, month, date, form]);

  return (
    <>
      <CollapsibleCard title="Buscar">
        <Form
          form={form}
          layout="inline"
          className="mb-3"
          initialValues={{ year, month, date: "" }}
        >
          <Form.Item label="Ano" name="year">
            <Input type="number" required />
          </Form.Item>
          <Form.Item label="Mês" name="month">
            <Input type="number" required />
          </Form.Item>
          <Form.Item label="Data" name="date">
            <Input type="date" required />
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              onClick={() => {
                if (form.getFieldValue("date")) {
                  setSearchParams({
                    period: "daily",
                    date: form.getFieldValue("date"),
                  });
                } else {
                  setSearchParams({
                    period: "daily",
                    year: form.getFieldValue("year") || "",
                    month: form.getFieldValue("month") || "",
                  });
                }
              }}
            >
              Filtrar
            </Button>
          </Form.Item>
          <Form.Item>
            <Button
              onClick={() => {
                setSearchParams({ period: "daily" });
                form.setFieldValue("year", "");
                form.setFieldValue("month", "");
                form.setFieldValue("date", "");
              }}
            >
              Limpar
            </Button>
          </Form.Item>
        </Form>
      </CollapsibleCard>
      <CollapsibleCard
        title={"Registros de PNL Diários"}
        bordered={true}
        collapsible={false}
        action={<EditPnlRecord mode="daily" />}
      >
        <PNLRecords // @ts-ignore comment
          data={data}
          additionalColumns={columns}
          isFetching={isFetching}
          columnsAfterWallets={afterColumns}
          page={page}
          setPage={setPage}
        />
      </CollapsibleCard>
    </>
  );
};

export default DailyPNLRecords;
