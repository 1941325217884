import { Button, Tooltip, Typography } from "antd";
import { useAssetDetailsDrawer } from "../providers/assetDetailsProvider";
import { SwapOutlined } from "@ant-design/icons";

const { Text } = Typography;

const TickerDetailsLink = ({
  ticker,
  displayedText,
}: {
  ticker: string;
  displayedText?: string;
}) => {
  const { showTickerDetails } = useAssetDetailsDrawer();
  const { switchTickerDetailsMode, currentTickerDetailsMode } =
    useAssetDetailsDrawer();

  return (
    <div className="whitespace-nowrap">
      <Text copyable={{ text: ticker }}>
        <Tooltip title={ticker}>
          <Typography.Link onClick={() => showTickerDetails({ ticker })}>
            {displayedText && currentTickerDetailsMode == "optionDetails"
              ? displayedText
              : ticker}
          </Typography.Link>
          {displayedText !== undefined && displayedText !== ticker && (
            <Button
              type="link"
              icon={<SwapOutlined />}
              onClick={() => switchTickerDetailsMode()}
            />
          )}
        </Tooltip>
      </Text>
    </div>
  );
};

export default TickerDetailsLink;
