export const numberToCurrency = (
  inputNumber: string | number | undefined,
  maximumFractionDigits: number = 2,
) => {
  if (!inputNumber) return "R$ 0,00";
  if (typeof inputNumber === "number") {
    inputNumber = inputNumber.toString();
  }
  return parseFloat(inputNumber).toLocaleString("pt-BR", {
    style: "currency",
    currency: "BRL",
    maximumFractionDigits: maximumFractionDigits,
  });
};

export const numberToDecimal = (inputNumber: string | number | undefined) => {
  if (!inputNumber) return "0,00";
  if (typeof inputNumber === "number") {
    inputNumber = inputNumber.toString();
  }
  return `${parseFloat(inputNumber).toFixed(2)}`;
};

export const sortString = (a: string, b: string) => {
  const list = [a, b].sort();
  return list[0] === a ? -1 : 1;
};

export const numberToPercentageDisplay = (
  inputNumber: string | number | undefined,
) => {
  if (!inputNumber) return "0%";
  if (typeof inputNumber === "number") {
    inputNumber = inputNumber.toString();
  }
  return `${parseFloat(inputNumber).toFixed(2)}%`;
};

export const decodeErrorMessage = (error: any) => {
  if (error?.response) {
    return error?.response?.data;
  }
  return error;
};

export const generateRandomColors = (amount: number): string[] => {
  const colors: string[] = [];

  const hexToRGB = (hex: string): number[] => {
    const bigint = parseInt(hex.slice(1), 16);
    return [(bigint >> 16) & 255, (bigint >> 8) & 255, bigint & 255];
  };

  const getRandomColor = (): string => {
    const letters = "0123456789ABCDEF";
    let color = "#";
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const calculateColorDistance = (color1: string, color2: string): number => {
    const rgb1 = hexToRGB(color1);
    const rgb2 = hexToRGB(color2);

    return Math.sqrt(
      Math.pow(rgb2[0] - rgb1[0], 2) +
        Math.pow(rgb2[1] - rgb1[1], 2) +
        Math.pow(rgb2[2] - rgb1[2], 2),
    );
  };

  while (colors.length < amount) {
    const newColor = getRandomColor();

    // Check if the new color is visually distinct from existing ones
    const isDistinct = colors.every(
      (existingColor) => calculateColorDistance(existingColor, newColor) > 100,
    );

    if (isDistinct) {
      colors.push(hexToRGB(newColor).toString());
    }
  }

  return colors;
};

export const enum NumberFormats {
  AMERICAN = "American",
  BRAZILIAN = "Brazilian",
}
export const convertNumberStringToValidNumber = (
  numberString: string,
  format: NumberFormats,
  isPrice: boolean,
) => {
  if (format === NumberFormats.BRAZILIAN) {
    // para amount, todos os pontos sao removidos, e a virgula é substituida por ponto
    if (!isPrice) {
      numberString = numberString
        ? numberString.replace(".", "").replace(",", ".")
        : "";
      // para price, todos os pontos sao removidos, e a virgula é substituida por ponto, além de R$ tbm ser removido
    } else {
      numberString = numberString
        ? numberString
            .replace(".", "")
            .replace("R$", "")
            .replace(",", ".")
            .replace(" ", "")
        : "";
    }
  } else {
    if (!isPrice) {
      // para amount, todas as virgulas sao removidas
      numberString = numberString ? numberString.replace(",", "") : "";
    } else {
      // para price, todas as virgulas sao removidas, além de R$ tbm ser removido
      numberString = numberString
        ? numberString.replace(",", "").replace("R$", "").replace(" ", "")
        : "";
    }
  }
  return numberString;
};

export const translateNumberToLetter = (number: number) => {
  return String.fromCharCode(65 + number);
};
