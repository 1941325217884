import { ConfigProvider } from "antd";
import ptBR from "antd/locale/pt_BR";
import ReactQueryProvider from "./providers/reactQueryProvider";
import AuthProvider from "./providers/authProvider";
import Layout from "./layouts";
import { Outlet } from "react-router-dom";
import AssetDetailsProvider from "./providers/assetDetailsProvider";

export default function App() {
  return (
    <ReactQueryProvider>
      <ConfigProvider locale={ptBR}>
        <AuthProvider>
          <Layout>
            <AssetDetailsProvider>
              <Outlet />
            </AssetDetailsProvider>
          </Layout>
        </AuthProvider>
      </ConfigProvider>
    </ReactQueryProvider>
  );
}
