import { SetStateAction } from "react";
import type { ListResponse } from "../../api/types";
import ResponsiveTable from "../../components/ResponsiveTable";
import { RankingAI } from "../../api/models";
import {
  numberToPercentageDisplay,
  translateNumberToLetter,
} from "../../services/utils";

const AIRecords = ({
  data,
  isFetching,
  page,
  setPage,
}: {
  data: ListResponse<any>;
  isFetching: boolean;
  page: number;
  setPage: (value: SetStateAction<number>) => void;
}) => {
  let columns = [
    {
      title: "Data",
      dataIndex: "date",
    },
    {
      title: "Ativo",
      dataIndex: "ticker",
    },
    {
      title: "Ranking",
      dataIndex: "predictedrank",
    },
    {
      title: "Percentil",
      dataIndex: "predictedpercentil",
      render: (value: number) => numberToPercentageDisplay(value),
    },
    {
      title: "Cluster",
      dataIndex: "cluster",
      render: (value: number) => translateNumberToLetter(value),
    },
    {
      title: "Presente nos indices",
      dataIndex: "code",
      render: (_: string, record: RankingAI) => (
        <>
          {record.ibov ? "IBOV " : ""}
          {record.ibxx ? "IBXX " : ""}
          {record.idiv ? "IDIV " : ""}
          {record.smll ? "SMALL " : ""}
        </>
      ),
    },
    {
      title: "Possui market maker?",
      dataIndex: "marketMaker",
      render: (value: number) => (value === 1 ? "Sim" : "Não"),
    },
    {
      title: "Horário do cálculo",
      dataIndex: "status",
      render: (value: string) => {
        if (value === "MID_DAY") {
          return "Meio dia";
        }
        return "Fechamento";
      },
    },
  ];

  return (
    <ResponsiveTable
      dataSource={data?.results}
      columns={columns}
      loading={isFetching}
      rowKey={(record: RankingAI) => record.code + record.date + record.status}
      className="w-full"
      size="small"
      pagination={{
        current: page,
        onChange: (page) => {
          setPage(page);
        },
        total: data?.count,
        pageSize: 10,
        showSizeChanger: false,
      }}
    />
  );
};

export default AIRecords;
