import { Form, Input, Button, message, InputNumber, Radio } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../api";
import { WALLET_QUERY_KEYS } from "../pages/wallet/constants";
import { createNewDate } from "../services/dates";
import { WalletNames } from "../api/models";
import CollapsibleCard from "./collapsibleCard";
import WalletSelection from "./walletSelection";

interface FormValues {
  ticker: string;
  amount: number;
  price: string;
  date: string;
  wallet?: WalletNames | string;
}

const NewOperationForm = ({
  ticker,
  amount,
  price,
  walletName,
  withCard = true,
  onSubmit = () => {},
}: {
  ticker?: string;
  amount?: number;
  price?: number;
  walletName?: WalletNames;
  withCard?: boolean;
  onSubmit?: () => void;
}) => {
  const queryClient = useQueryClient();
  const positionsKey = ["currentPositions"];
  const [mutationLoading, setMutationLoading] = useState(false);
  const [form] = Form.useForm();

  const newOperationMutation = useMutation({
    mutationFn: ({ ticker, amount, price, date, wallet }: FormValues) => {
      setMutationLoading(true);
      return appApi.stockOperations.create({
        ticker,
        amount,
        price, // API expects a string
        date,
        wallet: walletName || wallet !== "autoselected" ? wallet : undefined,
      });
    },
    onSuccess: async (response) => {
      if (response && response.accountDetailsCalculated === false) {
        message.warning(
          "Posição atualizada com sucesso porém devido a ausência de preços atualizados para algum dos ativos de sua carteira será necessário aguardar o recalculo dos dados, este processo pode levar alguns minutos e você será notificado quando ele for concluído.",
          8,
        );
      } else {
        message.success("Posição atualizada.");
      }
      queryClient.refetchQueries({ queryKey: positionsKey });
      WALLET_QUERY_KEYS.forEach(async (key) => {
        queryClient.invalidateQueries({ queryKey: [key] });
      });
      queryClient.invalidateQueries({ queryKey: ["accounts"] });
      setMutationLoading(false);
      onSubmit();
    },
    onError: async () => {
      await queryClient.refetchQueries({ queryKey: positionsKey });
      setMutationLoading(false);
    },
  });

  const defaultFormValues = {
    ticker: ticker || "",
    amount: amount || 100,
    price: price || "",
    operationType: amount ? (Number(amount) < 0 ? "sell" : "buy") : "buy",
    date: createNewDate().format("YYYY-MM-DD"),
    wallet: walletName || "autoselected",
  };

  const formRender = () => (
    <Form
      layout="vertical"
      form={form}
      onFinish={newOperationMutation.mutateAsync}
      initialValues={defaultFormValues}
    >
      <Form.Item
        name="ticker"
        label="Código do Ativo"
        rules={[{ required: true, message: "Por favor informe o Ticker" }]}
      >
        <Input placeholder="Código do Ativo" />
      </Form.Item>
      <Form.Item
        name="amount"
        label="Quantidade"
        rules={[{ required: true, message: "Por favor informe a Quantidade" }]}
      >
        <InputNumber
          precision={0}
          placeholder="Negativa para venda, positiva para compra"
          style={{ width: "100%" }}
          onChange={(value) => {
            form.setFieldsValue({ amount: value });
            form.setFieldsValue({
              operationType: value
                ? Number(value) < 0
                  ? "sell"
                  : "buy"
                : "buy",
            });
          }}
        />
      </Form.Item>
      <Form.Item label="Tipo de operação" name="operationType">
        <Radio.Group
          onChange={(value) => {
            form.setFieldsValue({ operationType: value.target.value });
            form.setFieldsValue({
              amount:
                Math.abs(form.getFieldValue("amount")) *
                (value.target.value === "buy" ? 1 : -1),
            });
          }}
        >
          <Radio.Button value="buy">Compra</Radio.Button>
          <Radio.Button value="sell">Venda</Radio.Button>
        </Radio.Group>
      </Form.Item>
      <Form.Item
        name="price"
        label="Preço"
        rules={[
          {
            required: true,
            message: "Por favor informe o preço médio da negociação",
          },
        ]}
      >
        <InputNumber
          min={0}
          precision={2}
          style={{ width: "100%" }}
          decimalSeparator=","
          placeholder="Preço médio da negociação"
        />
      </Form.Item>
      <Form.Item
        name="date"
        label="Data"
        rules={[
          {
            required: true,
            message: "Por favor informe a data da negociação",
          },
        ]}
      >
        <Input type="date" placeholder="Data da negociação" />
      </Form.Item>
      <Form.Item name="wallet" label="Carteira">
        <WalletSelection
          additionalOptions={[{ value: "autoselected", label: "Automática" }]}
        />
      </Form.Item>
      <Form.Item>
        <Button type="primary" htmlType="submit" loading={mutationLoading}>
          Salvar
        </Button>
      </Form.Item>
    </Form>
  );

  if (!withCard) {
    return formRender();
  }
  return (
    <CollapsibleCard
      title={
        <div className="w-full flex justify-between align-middle items-center">
          Nova operação
        </div>
      }
      classNameBody="p-4 pt-4 pb-1"
    >
      {formRender()}
    </CollapsibleCard>
  );
};

export default NewOperationForm;
