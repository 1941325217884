import {
  CurrentPosition,
  PositionStrategyBundle,
  WalletNames,
} from "../../api/models";
import { sortString } from "../../services/utils";
import { EditOutlined, PlusOutlined, RedoOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import TickerDetailsLink from "../../components/tickerDetailsLink";
import ResponsiveTable from "../../components/ResponsiveTable";
import CollapsibleCard from "../../components/collapsibleCard";
import { Button, Tooltip } from "antd";
import NewOperationModal from "../../components/newOperationModal";
import PriceDetails from "../../components/priceDetails";
import useScreenSize from "../../layouts/useScreenSize";
import PositionStrategyEditor from "./optionWallets/positionStrategyEditorModal";
import AutoGenerateStrategiesButton from "./optionWallets/autoGenerateStrategiesButton";
import { useMutation } from "@tanstack/react-query";
import { appApi } from "../../api";
import { message } from "antd";
import { useQueryClient } from "@tanstack/react-query";

type CurrentPositionWithChildren = CurrentPosition & {
  strategy: PositionStrategyBundle | null;
  children?: CurrentPosition[];
};

const WalletItemList = ({
  title,
  positions,
  strategies,
  isLoading,
  previousColumns,
  additionalColumns,
  walletName,
  walletId,
  displayWalletStrategy = true,
}: {
  title?: string | React.ReactNode | undefined;
  positions: CurrentPosition[];
  strategies?: PositionStrategyBundle[];
  isLoading: boolean;
  previousColumns?: any[];
  additionalColumns?: any[];
  walletName: WalletNames;
  walletId: string;
  isOptionWallet?: boolean;
  displayWalletStrategy?: boolean;
}) => {
  const [selectedPositionForOperation, setSelectedPositionForOperation] =
    useState<CurrentPosition | null>(null);
  const [newWalletOperationVisible, setNewWalletOperationVisible] =
    useState(false);
  const { isMobile, isTablet } = useScreenSize();
  const [mutationLoading, setMutationLoading] = useState(false);
  const queryClient = useQueryClient();

  const recalculateWalletMutation = useMutation({
    mutationFn: () => {
      setMutationLoading(true);
      return appApi.wallets.calculateWalletDetails({
        walletId,
        percentageTargeted: null,
      });
    },
    onSuccess: async (response) => {
      if (response && response.accountDetailsCalculated === false) {
        message.warning(
          "Carteira não atualizada devido a ausência de preços atualizados para algum dos ativos de sua carteira. Será necessário aguardar o recalculo dos dados, este processo pode levar alguns minutos e você será notificado quando ele for concluído.",
          8,
        );
      } else {
        message.success("Carteira atualizada.");
      }
      queryClient.refetchQueries({ queryKey: ["wallets"] });
      setMutationLoading(false);
    },
  });

  const hierarchyColumn = {
    title: "Ativo / Estratégia",
    dataIndex: "ticker",
    key: "ticker",
    sorter: (a: CurrentPosition, b: CurrentPosition) => {
      return sortString(a.ticker, b.ticker);
    },
    onFilter: (value: any, record: CurrentPosition) =>
      record.ticker.startsWith(value as string),
    filters: [...new Set(positions.map((row) => row.ticker)).values()].map(
      (ticker) => ({ text: ticker, value: ticker }),
    ),
    filterSearch: true,
    render: (ticker: string, record: CurrentPositionWithChildren) => {
      if (record.children) {
        return (
          <Tooltip title={`${record.strategy?.description || ""}`}>
            <div className="flex flex-row">
              <strong>{ticker}</strong>
              <PositionStrategyEditor
                strategyBundle={record.strategy as PositionStrategyBundle}
                walletName={walletName}
                walletId={walletId}
                walletPositions={positions}
              />
            </div>
          </Tooltip>
        );
      }
      return (
        <div className="flex flex-row">
          <TickerDetailsLink
            ticker={ticker}
            displayedText={record.optionAlias ? record.optionAlias : ticker}
          />
          <Button
            icon={<EditOutlined style={{ color: "blue" }} />}
            size="small"
            type="text"
            style={{ marginLeft: 8 }}
            onClick={() => setSelectedPositionForOperation(record)}
          />
        </div>
      );
    },
  };

  const hierarchyData: CurrentPositionWithChildren[] =
    (strategies
      ?.map((strategy) => {
        const strategyItems: CurrentPosition[] = strategy.items
          .map((item) => {
            const position = positions.find(
              (position) => position.id === item.position,
            ) as CurrentPosition;
            return position;
          })
          .filter((item) => item !== undefined) as CurrentPosition[];
        if (strategyItems && strategyItems.length > 0) {
          return {
            id: strategy.id,
            ticker: strategy.strategy,
            strategy: strategy,
            children: strategyItems,
          } as CurrentPositionWithChildren;
        }
        return null;
      })
      .filter((item) => item !== null) as CurrentPositionWithChildren[]) || [];

  const ListOfitemsInHierarchies = hierarchyData.map((hierarchy) => {
    const hierarchyChildrenList: string[] | undefined = hierarchy.children?.map(
      (child: CurrentPosition) => {
        return child.id;
      },
    );
    return hierarchyChildrenList;
  });

  // transform the array of arrays into a single array
  const itemsInHierarchies = ListOfitemsInHierarchies.filter(
    (item) => item != undefined && item != null,
  ).flat();

  const nonHierarchyData = positions.map((position) => {
    if (itemsInHierarchies.includes(position.id)) {
      return null;
    }
    return position;
  }) as CurrentPositionWithChildren[];

  // displayed data is the combination of hierarchyData and nonHierarchyData
  const displayedData = hierarchyData
    .concat(nonHierarchyData)
    .filter((item) => item !== null);

  let columns = [
    hierarchyColumn,
    {
      title: "Quantidade",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Preço Atual",
      dataIndex: "lastMarketPrice",
      key: "lastMarketPrice",
      render: (_: string, position: CurrentPositionWithChildren) => {
        if (position.children) {
          return null;
        }
        return (
          <PriceDetails
            price={position.lastMarketPrice}
            priceAsk={position.lastMarketPriceAsk}
            priceBid={position.lastMarketPriceBid}
            priceStatus={position.lastMarketPriceStatus}
            liquidityStatus={position.lastMarketPriceLiquidityStatus}
            priceDate={position.lastMarketPriceDate}
            showAsTooltip={true}
          />
        );
      },
    },
  ];
  if (previousColumns && previousColumns?.length > 0) {
    columns = previousColumns.concat(columns);
  }
  if (additionalColumns && additionalColumns?.length > 0) {
    columns = columns.concat(additionalColumns);
  }
  return (
    <CollapsibleCard
      title={title}
      action={
        <div className="flex flex-row justify-between">
          <Button
            icon={<PlusOutlined />}
            onClick={() => setNewWalletOperationVisible(true)}
            className="mr-2"
          >
            {isMobile || isTablet ? "" : "Nova Operação"}
          </Button>
          {displayWalletStrategy && (
            <>
              <PositionStrategyEditor
                walletName={walletName}
                walletId={walletId}
                walletPositions={positions}
              />
              <AutoGenerateStrategiesButton walletName={walletName} />
            </>
          )}
          <Button
            icon={<RedoOutlined />}
            loading={mutationLoading}
            onClick={() => recalculateWalletMutation.mutateAsync()}
            className="ml-2"
          >
            {isMobile || isTablet ? "" : "Recalcular"}
          </Button>
        </div>
      }
    >
      <ResponsiveTable
        dataSource={displayedData}
        columns={columns}
        size="small"
        loading={isLoading}
        rowKey="id"
      />
      {selectedPositionForOperation && (
        <NewOperationModal
          title={`Nova operação sobre o ativo ${selectedPositionForOperation.ticker}`}
          ticker={selectedPositionForOperation.ticker}
          amount={selectedPositionForOperation.amount * -1}
          price={parseFloat(selectedPositionForOperation.lastMarketPrice)}
          walletName={selectedPositionForOperation.walletName as WalletNames}
          visible={!!selectedPositionForOperation}
          onComplete={() => setSelectedPositionForOperation(null)}
        />
      )}
      {newWalletOperationVisible && (
        <NewOperationModal
          title={`Nova operação para a carteira ${walletName}`}
          amount={100}
          visible={newWalletOperationVisible}
          walletName={walletName}
          onComplete={() => setNewWalletOperationVisible(false)}
        />
      )}
    </CollapsibleCard>
  );
};

export default WalletItemList;
