import React, { createContext, useContext, useState } from "react";
import AssetDetailsDrawer from "../components/asset-details-drawer";
import {
  getPreferredTickerDetailsMode,
  savePreferredTickerDetailsMode,
  TickerDetailsMode,
} from "../services/localStorage";

export type AssetDetailsDisplay = {
  ticker: string;
};

interface AssetDetailsContextType {
  ticker: string;
  showTickerDetails: ({ ticker }: AssetDetailsDisplay) => void;
  switchTickerDetailsMode: () => void;
  currentTickerDetailsMode: TickerDetailsMode;
}

const AssetDetailsContext = createContext<AssetDetailsContextType | undefined>(
  undefined,
);

export const AssetDetailsProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [ticker, setTicker] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [currentTickerDetailsMode, setCurrentTickerDetailsMode] =
    useState<TickerDetailsMode>(getPreferredTickerDetailsMode());

  const showTickerDetails = ({ ticker }: AssetDetailsDisplay) => {
    setTicker(ticker);
    setOpen(true);
  };

  const switchTickerDetailsMode = () => {
    const newMode =
      currentTickerDetailsMode === "optionDetails" ? "ticker" : "optionDetails";
    savePreferredTickerDetailsMode(newMode);
    setCurrentTickerDetailsMode(newMode);
  };

  return (
    <AssetDetailsContext.Provider
      value={{
        ticker,
        showTickerDetails,
        switchTickerDetailsMode,
        currentTickerDetailsMode,
      }}
    >
      {children}
      <AssetDetailsDrawer
        open={open && ticker !== ""}
        onClose={() => setOpen(false)}
        ticker={ticker}
      />
    </AssetDetailsContext.Provider>
  );
};

export const useAssetDetailsDrawer = () => {
  const context = useContext(AssetDetailsContext);
  if (!context) {
    throw new Error(
      "useAssetDetailsDrawer must be used within an AssetDetailsProvider",
    );
  }
  return context;
};

export default AssetDetailsProvider;
