import { Button, Modal, Form, Input, Select, message, Divider } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../api";
import {
  decodeWalletCodes,
  StockWalletCodes,
  WalletCodes,
} from "../../api/models";
import { useAuth } from "../../providers/authProvider";

interface FormValues {
  name: string;
  code: string;
  automationAlgorithm?: string | null;
}

export const EditWalletModal = ({
  walletId,
  walletName,
  walletCode,
  walletAutomationAlgorithm,
}: {
  walletId?: string;
  walletName?: string;
  walletCode?: string;
  walletAutomationAlgorithm?: string | null;
}) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [mutationLoading, setMutationLoading] = useState(false);
  const queryClient = useQueryClient();
  const [enableAlgoSelection, setEnableAlgoSelection] = useState(
    walletCode === WalletCodes.ACOES,
  );
  const { isAdmin, hasAIAccess } = useAuth();

  const createWalletMutation = useMutation({
    mutationFn: ({ name, code, automationAlgorithm }: FormValues) => {
      setMutationLoading(true);
      return appApi.wallets.create({
        name: name,
        code: code,
        automationAlgorithm: automationAlgorithm,
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["wallets"] });
      setMutationLoading(false);
      setVisible(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  const editWalletMutation = useMutation({
    mutationFn: ({ name, code, automationAlgorithm }: FormValues) => {
      setMutationLoading(true);
      return appApi.wallets.update({
        id: walletId || "",
        name: name,
        code: code,
        automationAlgorithm: automationAlgorithm,
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["wallets"] });
      setMutationLoading(false);
      setVisible(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  const deleteWalletMutation = useMutation({
    mutationFn: () => {
      setMutationLoading(true);
      return appApi.wallets.delete(walletId as string);
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["wallets", "simplified"] });
      setMutationLoading(false);
      setVisible(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  return (
    <div>
      <Button type="link" onClick={() => setVisible(true)}>
        {!walletId ? "Nova carteira" : <EditOutlined />}
      </Button>
      <Modal
        title={!walletId ? "Nova carteira" : "Editar carteira"}
        open={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
        confirmLoading={mutationLoading}
        footer={(_, { OkBtn, CancelBtn }) => (
          <>
            {walletId && (
              <Button danger onClick={() => deleteWalletMutation.mutateAsync()}>
                Excluir carteira
              </Button>
            )}
            <CancelBtn />
            <OkBtn />
          </>
        )}
      >
        <Form
          form={form}
          layout="horizontal"
          onFinish={() => {
            const { name, code, automationAlgorithm } = form.getFieldsValue();
            if (!name || !code) {
              message.error(
                "Por favor informe um nome e um tipo para a carteira.",
              );
            }
            if (walletId) {
              editWalletMutation.mutateAsync({
                name,
                code,
                automationAlgorithm,
              });
            } else {
              createWalletMutation.mutateAsync({
                name,
                code,
                automationAlgorithm,
              });
            }
          }}
          initialValues={{
            name: walletName,
            code: walletCode,
            automationAlgorithm: walletAutomationAlgorithm,
          }}
        >
          <Divider />
          <Form.Item name="name" label="Nome da carteira" required>
            <Input
              placeholder="Nome da carteira"
              style={{ width: "100%" }}
              type="text"
            />
          </Form.Item>
          <Form.Item
            name="code"
            label="Tipo de carteira"
            required
            help="Apenas Carteiras do tipo 'Ações' são contabilizadas nos cálculos das estratégias da FIA de Renda e Carrego."
          >
            <Select
              onChange={(value) => setEnableAlgoSelection(value === "ACOES")}
            >
              {Object.keys(StockWalletCodes).map((key) => (
                <Select.Option key={key} value={key}>
                  {decodeWalletCodes(key)}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
          <Form.Item
            name="automationAlgorithm"
            label="Algoritmo de automação"
            help="Indica qual sistema de avisos será aplicado para auxiliar na gestão desta carteira. Requer acesso ao módulo de IA do FIAInvest."
            hidden={!enableAlgoSelection}
          >
            <Select disabled={!isAdmin && !hasAIAccess} allowClear>
              <Select.Option key={"ML_WALLET"} value={"ML_WALLET"}>
                Machine Learning
              </Select.Option>
            </Select>
          </Form.Item>
          <Divider />
        </Form>
      </Modal>
    </div>
  );
};
