import { Button, Modal, Form, InputNumber, Input, Spin } from "antd";
import { useState } from "react";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../api";
import { WalletOverrides, SimplifiedWallet } from "../../api/models";
import { useQuery } from "@tanstack/react-query";
import { MonthSelectionInput } from "../../components/monthSelectionInput";

interface FormValues {
  year: number;
  month: number;
  date: string;
  acoes: string | null;
  internacional: string | null;
  renda: string | null;
  carrego: string | null;
  figuras: string | null;
  fundosImobiliarios: string | null;
  fundosDoAgronegocio: string | null;
  outros: string | null;
}

type EditPnlRecordProps = {
  year?: number;
  month?: number;
  date?: string;
  mode: "daily" | "monthly";
  walletOverrides?: WalletOverrides[];
};

export const EditPnlRecord = ({
  year,
  month,
  date,
  mode,
  walletOverrides,
}: EditPnlRecordProps) => {
  const [visible, setVisible] = useState(false);
  const [form] = Form.useForm();
  const [mutationLoading, setMutationLoading] = useState(false);
  const queryClient = useQueryClient();

  const { data, isLoading } = useQuery<SimplifiedWallet[]>({
    queryKey: ["wallets", "simplified", "option"],
    queryFn: () => appApi.wallets.getSimplifiedWalletsList(null),
  });

  const emptyWalletOverrides: WalletOverrides[] = data
    ? data.map((wallet) => ({
        name: wallet.name,
        pnl: null,
      }))
    : [];
  const wallets = walletOverrides || emptyWalletOverrides;
  const sortedWallets = wallets.sort((a, b) => a.name.localeCompare(b.name));

  const mutation = useMutation({
    mutationFn: (values: FormValues) => {
      setMutationLoading(true);
      const { year, month, date, ...rest } = values;
      // iterate over keys and values in rest and build walletResults payload
      const walletResults: WalletOverrides[] = [];
      for (const [key, value] of Object.entries(rest)) {
        walletResults.push({
          name: key,
          pnl: value !== null ? Number(value) : null,
        });
      }
      if (mode === "daily") {
        return appApi.pnl.overrideDailyPnlWalletResults({
          date,
          walletResults: walletResults.filter((wallet) => wallet.pnl !== null),
        });
      }
      return appApi.pnl.overrideMonthlyPnlWalletResults({
        year,
        month,
        walletResults: walletResults.filter((wallet) => wallet.pnl !== null),
      });
    },
    onSuccess: async () => {
      queryClient.invalidateQueries({ queryKey: ["dailyPNLRecords"] });
      queryClient.invalidateQueries({ queryKey: ["monthlyPNLRecords"] });
      queryClient.invalidateQueries({ queryKey: ["yearlyPNLRecords"] });
      queryClient.invalidateQueries({ queryKey: ["pnlChartData"] });
      setMutationLoading(false);
      setVisible(false);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  if (isLoading) {
    return <Spin />;
  }

  return (
    <div>
      <Button type="link" onClick={() => setVisible(true)}>
        {mode == "monthly" && !month
          ? "Novo"
          : mode == "daily" && !date
            ? "Novo"
            : "Editar"}
      </Button>
      <Modal
        title={
          !year || !month
            ? `Novo registro de PNL ${mode == "monthly" ? "mensal" : "diário"}`
            : `Editar registro de PNL ${mode == "monthly" ? "mensal" : "diário"}`
        }
        open={visible}
        onCancel={() => {
          setVisible(false);
          form.resetFields();
        }}
        onOk={() => form.submit()}
        confirmLoading={mutationLoading}
      >
        <Form
          form={form}
          layout="horizontal"
          onFinish={() => mutation.mutateAsync(form.getFieldsValue())}
        >
          {mode === "daily" && (
            <Form.Item name="date" label="Data" initialValue={date}>
              <Input
                placeholder="Data"
                style={{ width: "100%" }}
                disabled={!!date}
                type="date"
              />
            </Form.Item>
          )}
          {mode === "monthly" && (
            <>
              <Form.Item name="year" label="Ano" initialValue={year}>
                <InputNumber
                  placeholder="Ano"
                  style={{ width: "100%" }}
                  disabled={!!year}
                  type="number"
                />
              </Form.Item>
              <MonthSelectionInput
                name="month"
                label="Mês"
                disabled={month !== undefined}
                initialValue={month}
                help={
                  "Deixe o campo da carteira que não deseja sobrepor em branco."
                }
              />
            </>
          )}
          {sortedWallets.map((wallet) => (
            <Form.Item
              key={wallet.name}
              name={wallet.name}
              label={wallet.name}
              initialValue={wallet.pnl}
            >
              <InputNumber
                placeholder={wallet.name}
                style={{ width: "100%" }}
                prefix="R$"
                decimalSeparator=","
              />
            </Form.Item>
          ))}
        </Form>
      </Modal>
    </div>
  );
};
