import { useState } from "react";
import type { ListResponse } from "../../api/types";
import { RankingAI } from "../../api/models";
import { useQuery } from "@tanstack/react-query";
import { appApi } from "../../api";
import { Button, Checkbox, Form, Input, Select } from "antd";
import CollapsibleCard from "../../components/collapsibleCard";
import AIRecords from "./dataTable";
import { translateNumberToLetter } from "../../services/utils";

const RankingAIPage = () => {
  const queryKey = "rankingAI";

  const [page, setPage] = useState(1);
  const [searchIndex, setSearchIndex] = useState(0);
  const [date, setDate] = useState(undefined);
  const [code, setCode] = useState(undefined);
  const [cluster, setCluster] = useState(undefined);
  const [ibov, setIbov] = useState(false);
  const [ibxx, setIbxx] = useState(false);
  const [idiv, setIdiv] = useState(false);
  const [smll, setSmll] = useState(false);
  const [calculationPeriod, setCalculationPeriod] = useState<
    "MID_DAY" | "OPEN" | undefined
  >(undefined);
  const [marketMaker, setMarketMaker] = useState(false);

  const { data: clusters } = useQuery({
    queryKey: ["clusters"],
    queryFn: () => appApi.pluriiAI.listClusters(),
  });

  const { data, isFetching } = useQuery<ListResponse<RankingAI>>({
    queryKey: [queryKey, searchIndex, page],
    queryFn: () =>
      appApi.pluriiAI.listRanking(
        page,
        date,
        code,
        cluster,
        idiv,
        ibov,
        ibxx,
        smll,
        calculationPeriod, // status
        marketMaker,
      ),
  });
  const [form] = Form.useForm();

  const performSearch = () => {
    setSearchIndex(searchIndex + 1);
    setPage(1);
    const formValues = form.getFieldsValue();
    if (formValues.date) {
      setDate(formValues.date.toString());
    } else {
      setDate(undefined);
    }
    if (formValues.code) {
      setCode(formValues.code);
    } else {
      setCode(undefined);
    }
    if (formValues.cluster !== undefined && formValues.cluster !== null) {
      setCluster(formValues.cluster);
    } else {
      setCluster(undefined);
    }
    setMarketMaker(formValues.marketmaker);
    setIbov(formValues.ibov);
    setIbxx(formValues.ibxx);
    setIdiv(formValues.idiv);
    setSmll(formValues.smll);
    setCalculationPeriod(formValues.calculationPeriod);
  };

  const clearForm = () => {
    form.resetFields();
    setSearchIndex(searchIndex + 1);
    setPage(1);
  };

  return (
    <CollapsibleCard title="Ranking IA" bordered={true} collapsible={false}>
      <Form
        form={form}
        layout="inline"
        className="mb-3"
        initialValues={{
          date,
          code,
          cluster,
          marketMaker,
          ibov,
          ibxx,
          idiv,
          smll,
        }}
      >
        <Form.Item label="Data" name="date">
          <Input type="date" required />
        </Form.Item>
        <Form.Item label="Ativo" name="code">
          <Input />
        </Form.Item>
        <Form.Item name="cluster">
          <Select placeholder="Cluster" allowClear>
            {clusters?.map((cluster) => (
              <Select.Option key={cluster} value={cluster}>
                {translateNumberToLetter(cluster)}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="marketmaker" valuePropName="checked">
          <Checkbox>Market Maker</Checkbox>
        </Form.Item>
        <Form.Item name="ibov" valuePropName="checked">
          <Checkbox>IBOV</Checkbox>
        </Form.Item>
        <Form.Item name="ibxx" valuePropName="checked">
          <Checkbox>IBXX</Checkbox>
        </Form.Item>
        <Form.Item name="idiv" valuePropName="checked">
          <Checkbox>IDIV</Checkbox>
        </Form.Item>
        <Form.Item name="smll" valuePropName="checked">
          <Checkbox>SMALL</Checkbox>
        </Form.Item>
        <Form.Item name="calculationPeriod">
          <Select placeholder="Período">
            <Select.Option value="MID_DAY">Meio dia</Select.Option>
            <Select.Option value="OPEN">Fechamento</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item>
          <Button type="primary" onClick={() => performSearch()}>
            Filtrar
          </Button>
        </Form.Item>
        <Form.Item>
          <Button onClick={() => clearForm()}>Limpar</Button>
        </Form.Item>
      </Form>
      <AIRecords // @ts-ignore comment
        data={data}
        isFetching={isFetching}
        page={page}
        setPage={setPage}
      />
    </CollapsibleCard>
  );
};

export default RankingAIPage;
