import { CurrentPosition } from "../../../api/models";
import {
  generateRandomColors,
  translateNumberToLetter,
} from "../../../services/utils";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Doughnut } from "react-chartjs-2";
import { useEffect } from "react";

ChartJS.register(ArcElement, Tooltip, Legend);

// build object with a map of cluster to total value of positions in that cluster
const buildClusterMap = (positions: CurrentPosition[]) => {
  const clusterMap = new Map<string, { label: string; amount: number }>();
  positions.forEach((position) => {
    const cluster = translateNumberToLetter(
      parseInt(position.mlCluster || "0"),
    );
    if (clusterMap.has(cluster)) {
      clusterMap.set(cluster, {
        label: clusterMap.get(cluster)?.label! + ", " + position.ticker,
        amount:
          clusterMap.get(cluster)?.amount! + parseFloat(position.positionTotal),
      });
    } else {
      clusterMap.set(cluster, {
        label: `${cluster}: ${position.ticker}`,
        amount: parseFloat(position.positionTotal),
      });
    }
  });
  return clusterMap;
};

const MLClustersAllocationChart = ({
  positions,
}: {
  positions: CurrentPosition[];
}) => {
  if (!positions || positions.length === 0) {
    return <p>Nenhuma posição ainda na carteira.</p>;
  }

  const clusterMap = buildClusterMap(positions);
  const colors = generateRandomColors(clusterMap.size);
  const colorsRGBA = colors.map((color) => `rgba(${color}, 0.8)`);

  // subscribing to the visibility changed event and re-rendering the chart whenever the tab transitions to visible
  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        const chart = ChartJS.getChart("clustersAllocationChart");
        if (chart) {
          chart.update();
        }
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  const data = {
    labels: Array.from(clusterMap.values()).map((cluster) => cluster.label),
    datasets: [
      {
        label: "Total das posições no cluster",
        data: Array.from(clusterMap.values()).map((cluster) => cluster.amount),
        backgroundColor: colorsRGBA,
      },
    ],
  };

  return <Doughnut data={data} id="clustersAllocationChart" />;
};

export default MLClustersAllocationChart;
