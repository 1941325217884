import {
  Alert,
  Col,
  Row,
  Form,
  Spin,
  Button,
  message,
  Slider,
  Divider,
  SliderSingleProps,
  Tooltip,
} from "antd";
import type { ListResponse } from "../../../api/types";
import { useEffect, useState } from "react";
import {
  DefensiveWalletDetails,
  Wallet,
  CurrentPosition,
  WalletNames,
} from "../../../api/models";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useQuery, useMutation, useQueryClient } from "@tanstack/react-query";
import { appApi } from "../../../api";
import WalletItemList from "../walletItemsList";
import {
  numberToDecimal,
  numberToPercentageDisplay,
} from "../../../services/utils";
import DeltaHedgeOptionsMonitor, {
  OperationType,
  OptionType,
} from "./dhOptionsMonitor";
import { decodeOptionAction } from "../../../api/models";
import CollapsibleCard from "../../../components/collapsibleCard";
import WalletMetricProgressBar from "./walletMetricProgressBar";
import OptionDetails from "../../../components/optionDetails";
import DhNotificationMode from "./dhNotificationMode";

interface EditableFields {
  id: string;
  percentageTargeted: number;
}

const columns = [
  {
    title: "Delta da opção",
    dataIndex: "optionDeltaDisplay",
    key: "optionDeltaDisplay",
    render: (value: string, record: CurrentPosition) => {
      return (
        <div>
          <span className="mr-2">{value}</span>
          <OptionDetails position={record} showAsTooltip />
        </div>
      );
    },
  },
  {
    title: "Ação a realizar",
    dataIndex: "optionActionToPerform",
    key: "optionActionToPerform",
    render: (value: string) => (value ? decodeOptionAction(value) : ""),
  },
  {
    title: "Notificações de DH",
    dataIndex: "positionNotificationFreezeMode",
    render: (_: string, record: CurrentPosition) => {
      return <DhNotificationMode currentPosition={record} />;
    },
  },
];

const DefensiveWallet = () => {
  const [form] = Form.useForm();
  const [mutationLoading, setMutationLoading] = useState(false);
  const queryClient = useQueryClient();
  const [simulationData, setSimulationData] =
    useState<DefensiveWalletDetails>();

  const { data, isLoading } = useQuery<ListResponse<Wallet>>({
    queryKey: ["wallets", WalletNames.DEFENSIVE],
    queryFn: () => appApi.wallets.getList(WalletNames.DEFENSIVE),
  });

  const editWalletMutation = useMutation({
    mutationFn: ({ id, percentageTargeted }: EditableFields) => {
      setMutationLoading(true);
      return appApi.wallets.update({
        id,
        percentageTargeted: percentageTargeted,
      });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({
        queryKey: ["wallets", WalletNames.DEFENSIVE],
      });
      message.success("Carteira atualizada.");
      setMutationLoading(false);
    },
    onError: async () => {
      await queryClient.refetchQueries({
        queryKey: ["wallets", WalletNames.DEFENSIVE],
      });
      setMutationLoading(false);
    },
  });

  const calculateWalletDetailsMutation = useMutation({
    mutationFn: ({ id, percentageTargeted }: EditableFields) => {
      setMutationLoading(true);
      return appApi.wallets.calculateWalletDetails({
        walletId: id,
        percentageTargeted: percentageTargeted,
      });
    },
    onSuccess: async (response: any) => {
      setMutationLoading(false);
      setSimulationData(response);
    },
    onError: async () => {
      setMutationLoading(false);
    },
  });

  useEffect(() => {
    if (data?.results[0]) {
      form.setFieldsValue({
        percentageTargeted: data.results[0].percentageTargeted,
      });
    }
  }, [data]);

  if (isLoading) {
    return <Spin />;
  }

  let positions: CurrentPosition[] = [];
  const wallet: Wallet | undefined = data?.results[0];
  if (wallet) {
    positions = wallet.positions;
  }

  const walletDetails: DefensiveWalletDetails | undefined =
    wallet?.walletDetails;
  if (!wallet) {
    return <Alert message="Nenhuma carteira encontrada" type="error" />;
  }

  const buttonsDisabled = mutationLoading || isLoading;
  const marks: SliderSingleProps["marks"] = {
    0: "0%",
    50: "50%",
    100: "100%",
    130: "130%",
  };
  return (
    <Row className="w-full">
      <Col xs={24} sm={24} md={8} lg={8} xl={8} className="w-full">
        <CollapsibleCard title="Proteção">
          <Form form={form} layout="vertical">
            {walletDetails?.valueWalletBeta && (
              <div className="ml-2">
                <p>
                  Percentual sugerido de proteção:{" "}
                  {numberToPercentageDisplay(
                    Math.round(walletDetails.valueWalletBeta * 100),
                  )}
                </p>
                <p>
                  (Beta considerando todas as carteiras de ações:{" "}
                  {numberToDecimal(walletDetails.valueWalletBeta)})
                </p>
              </div>
            )}
            <Form.Item name="percentageTargeted" className="pr-3 pl-3">
              <Slider
                min={0}
                max={130}
                step={5}
                marks={marks}
                tooltip={{
                  formatter: (value: number | undefined) => (
                    <span>{value ? `${value}%` : "0%"}</span>
                  ),
                }}
              />
            </Form.Item>
            <Form.Item>
              <Button
                type="default"
                loading={mutationLoading}
                disabled={buttonsDisabled}
                onClick={() =>
                  calculateWalletDetailsMutation.mutateAsync({
                    id: wallet.id,
                    percentageTargeted:
                      form.getFieldValue("percentageTargeted"),
                  })
                }
                className="mr-2"
              >
                Simular escolha
              </Button>
              <Button
                type="primary"
                loading={mutationLoading}
                disabled={buttonsDisabled}
                onClick={() =>
                  editWalletMutation.mutateAsync({
                    id: wallet.id,
                    percentageTargeted:
                      form.getFieldValue("percentageTargeted"),
                  })
                }
              >
                Salvar escolha
              </Button>
            </Form.Item>
          </Form>
          {simulationData && (
            <>
              <Divider />
              <h4>Simulação</h4>
              <p>
                Delta da proteção simulada:{" "}
                {numberToDecimal(simulationData.desiredProtectionDeltaTarget)}
              </p>
              <h4>Mini índice</h4>
              <p>
                Quantidade alvo de mini índices para a carteira:{" "}
                {simulationData?.expectedIndexSellAmount}
              </p>
              <h4>PUTs de BOVA11</h4>
              <p>
                Vendidas (Delta 25-30):{" "}
                {simulationData?.expectedBova11SoldPutAmount}
              </p>
              <p>
                Compradas (Delta 6-8):{" "}
                {simulationData?.expectedBova11PurchasedPutAmount}
              </p>
              <h4>CALLs de BOVA11</h4>
              <p>
                Compradas (Delta 6-8):{" "}
                {simulationData?.expectedBova11PurchasedCallAmount}
              </p>
            </>
          )}
        </CollapsibleCard>
        <CollapsibleCard title="Dados da carteira">
          {walletDetails ? (
            <>
              <p>
                Proteção atual da carteira:{" "}
                {numberToDecimal(wallet.percentageTargeted)}%
              </p>
              <p>
                Delta da carteira de ações:{" "}
                {numberToDecimal(walletDetails.totalDeltaInValueWallet)}
              </p>
              <p>
                Delta de proteção buscado:{" "}
                {numberToDecimal(walletDetails.desiredProtectionDeltaTarget)}
              </p>
              <h4>Mini índice</h4>
              {walletDetails?.currentSoldBova11Amount && (
                <>
                  <p>
                    Quantidade vendida de BOVA11 atualmente:{" "}
                    {walletDetails.currentSoldBova11Amount}
                  </p>
                  <p>
                    Delta da posição vendida de BOVA11:{" "}
                    {walletDetails.soldBova11Delta}
                  </p>
                </>
              )}
              <WalletMetricProgressBar
                label="Vendidos"
                current={walletDetails?.currentIndexSellAmount}
                target={walletDetails?.expectedIndexSellAmount}
              />
              <h4>PUTs de BOVA11</h4>
              <WalletMetricProgressBar
                label="Vendidas (Delta 25-30)"
                current={walletDetails?.currentBova11SoldPutAmount}
                target={walletDetails?.expectedBova11SoldPutAmount}
              />
              <WalletMetricProgressBar
                label={
                  <Tooltip title="Somente são considerados neste calculo os pozinhos no mesmo vencimento da PUT vendida e que estejam com delta acima de 3%">
                    Compradas (Delta 6-8) <InfoCircleOutlined />
                  </Tooltip>
                }
                current={walletDetails?.currentBova11PurchasedPutAmount}
                target={walletDetails?.expectedBova11PurchasedPutAmount}
              />
              <h4>CALLs de BOVA11</h4>
              <WalletMetricProgressBar
                label={
                  <Tooltip title="Somente são considerados neste calculo os pozinhos no mesmo vencimento da PUT vendida e que estejam com delta acima de 3%">
                    Compradas (Delta 6-8) <InfoCircleOutlined />
                  </Tooltip>
                }
                current={walletDetails?.currentBova11PurchasedCallAmount}
                target={walletDetails?.expectedBova11PurchasedCallAmount}
              />
            </>
          ) : (
            <p>Calculando... </p>
          )}
        </CollapsibleCard>
        <CollapsibleCard title="Orientações" defaultCollapsed={true}>
          <h3>Montagem</h3>
          <p>
            <strong>"n"</strong> = Delta / BOVA11
          </p>
          <p>
            <strong>Venda</strong> de{" "}
            <strong>2x "n" Put delta % 23 a 28</strong>
          </p>
          <p>
            <strong>Short mini-índice</strong>
          </p>
          <p>
            <strong>Compra</strong> de <strong>"n" Put delta % 6 a 8</strong>
          </p>
          <p>
            <strong>Compra</strong> de <strong>"n" Call delta % 6 a 8</strong>
          </p>
          <br />
          <h3>Rolagem</h3>
          <p>
            <strong>2 a 3 semanas</strong> antes do vencimento
          </p>
          <p>
            <strong>Rolar</strong> venda de <strong>Put</strong>
          </p>
          <p>
            <strong>Rolar</strong> Compra de <strong>Put</strong> pozinho se{" "}
            <strong>Delta % {">"} 6</strong>
          </p>
          <p>
            <strong>Rolar</strong> Compra de <strong>Call</strong> pozinho se{" "}
            <strong>Delta % {">"} 6</strong>
          </p>
          <br />
          <h3>Delta Hedge</h3>
          <p>
            <strong>Monitorar diariamente</strong>
          </p>
          <p>
            <strong>Rolar</strong> venda de{" "}
            <strong>
              Put Delta % {"<"} 15% ou {">"} 45%
            </strong>
          </p>
          <p>
            <strong>Rolar</strong> compra de{" "}
            <strong>Put pozinho se Delta % {">"} 6%</strong>
          </p>
          <p>
            <strong>Rolar</strong> venda de{" "}
            <strong>Call pozinho se Delta % {">"} 6%</strong>
          </p>
        </CollapsibleCard>
      </Col>
      <Col sm={24} md={16} className="w-full">
        <WalletItemList
          title="Carteira de carrego"
          positions={positions}
          strategies={wallet?.strategies}
          isLoading={isLoading}
          additionalColumns={columns}
          walletName={WalletNames.DEFENSIVE}
          walletId={wallet.id}
        />
        <Row className="w-full">
          <CollapsibleCard title="Opções disponívels para negociar">
            <DeltaHedgeOptionsMonitor
              monitorTabs={[
                {
                  key: "bova11_puts_sell",
                  label: "Venda de PUTs de BOVA11",
                  operationType: OperationType.SELL,
                  optionType: OptionType.PUT,
                  ticker: "BOVA11",
                  minDelta: 18,
                  maxDelta: 35,
                },
                {
                  key: "bova11_puts_buy",
                  label: "Compra de PUTs de BOVA11",
                  operationType: OperationType.BUY,
                  optionType: OptionType.PUT,
                  ticker: "BOVA11",
                  minDelta: 4,
                  maxDelta: 10,
                },
                {
                  key: "bova11_calls_buy",
                  label: "Compra de CALLs de BOVA11",
                  operationType: OperationType.BUY,
                  optionType: OptionType.CALL,
                  ticker: "BOVA11",
                  minDelta: 5,
                  maxDelta: 10,
                },
              ]}
            />
          </CollapsibleCard>
        </Row>
      </Col>
    </Row>
  );
};

export default DefensiveWallet;
