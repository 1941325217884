import { useState } from "react";
import { Popconfirm, message } from "antd";
import type { ListResponse } from "../../../api/types";
import { StockOperation } from "../../../api/models";
import { useQuery, useQueryClient, useMutation } from "@tanstack/react-query";
import { appApi } from "../../../api";
import { WALLET_QUERY_KEYS } from "../constants";
import TickerDetailsLink from "../../../components/tickerDetailsLink";
import ResponsiveTable from "../../../components/ResponsiveTable";
import CollapsibleCard from "../../../components/collapsibleCard";
import PriceDisplay from "../../../components/priceDisplay";
import TableStringFilter from "../../../components/tableStringFilter";

const OperationsHistory = () => {
  const queryClient = useQueryClient();
  const queryKey = "operationsHistory";
  const [page, setPage] = useState(1);
  const [mutationLoading, setMutationLoading] = useState(false);
  const [filteredTicker, setFilteredTicker] = useState<string | null>(null);
  const [sortedColumn, setSortedColumn] = useState<string | null>(null);
  const [sortedOrder, setSortedOrder] = useState<string | null>(null);

  const { data, isLoading, isRefetching } = useQuery<
    ListResponse<StockOperation>
  >({
    queryKey: [queryKey, page, filteredTicker, sortedColumn, sortedOrder],
    queryFn: () =>
      appApi.stockOperations.getList(
        page,
        filteredTicker,
        10,
        sortedColumn,
        sortedOrder,
      ),
  });
  const mutation = useMutation({
    mutationFn: ({ id }: { id: string }) => {
      setMutationLoading(true);
      return appApi.stockOperations.delete({ id });
    },
    onSuccess: async () => {
      await queryClient.refetchQueries({ queryKey: [queryKey, page] });
      WALLET_QUERY_KEYS.forEach(async (key) => {
        await queryClient.invalidateQueries({ queryKey: [key] });
      });
      queryClient.invalidateQueries({ queryKey: ["accounts"] });
      message.success("Operação excluída.");
      setMutationLoading(false);
    },
    onError: async () => {
      await queryClient.refetchQueries({
        queryKey: [queryKey, page],
      });
      setMutationLoading(false);
    },
  });

  const columns = [
    {
      title: "Data",
      dataIndex: "date",
      key: "date",
      sorter: true,
    },
    {
      title: "Ticker",
      dataIndex: "ticker",
      key: "ticker",
      render: (value: string) => <TickerDetailsLink ticker={value} />,
      sorter: true,
    },
    {
      title: "Quantidade",
      dataIndex: "amount",
      key: "amount",
    },
    {
      title: "Preço",
      dataIndex: "price",
      key: "price",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "Carteira selecionada",
      dataIndex: "wallet",
      key: "wallet",
    },
    {
      title: "Descrição",
      dataIndex: "description",
      key: "description",
    },
    {
      title: "Operação",
      dataIndex: "id",
      render: (id: string) => (
        <Popconfirm
          title="Tem certeza que deseja excluir?"
          description="Esta operação afetará sua posição atual, mas não refletirá nos calculos passados de PnL da sua carteira."
          onConfirm={() => mutation.mutateAsync({ id })}
        >
          <a>Excluir</a>
        </Popconfirm>
      ),
    },
  ];

  const setFilter = (value: string | null) => {
    setFilteredTicker(value);
  };
  const handleTableChange = (_: any, __: any, sorter: any) => {
    if (sorter) {
      setSortedColumn(sorter.field);
      setSortedOrder(sorter?.order === "ascend" ? "asc" : "desc");
    }
  };

  return (
    <CollapsibleCard title="Histórico de Operações" collapsible={false}>
      <ResponsiveTable
        dataSource={data?.results}
        columns={columns}
        size="small"
        title={() => <TableStringFilter label="Ativo" setFilter={setFilter} />}
        loading={isLoading || mutationLoading || isRefetching}
        pagination={{
          current: page,
          onChange: (page) => {
            setPage(page);
          },
          total: data?.count,
          pageSize: 10,
          showSizeChanger: false,
        }}
        rowKey="id"
        onChange={handleTableChange}
      />
    </CollapsibleCard>
  );
};

export default OperationsHistory;
