import { Tabs } from "antd";
import type { TabsProps } from "antd";
import DefensiveWallet from "./defensiveWallet";
import IncomeWallet from "./incomeWallet";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { Spin } from "antd";
import { appApi } from "../../../api";
import { SimplifiedWallet } from "../../../api/models";
import PicturesWallet from "./picturesWallet/picturesWallet";
import ExerciseScreen from "./exerciseScreen";

export enum TabKeys {
  VALUE = "value",
  INCOME = "income",
  DEFENSIVE = "defensive",
  PICTURES = "pictures",
  EXERCISE = "exercise",
}

const items: TabsProps["items"] = [
  {
    key: TabKeys.INCOME,
    label: "Renda",
    children: <IncomeWallet />,
  },
  {
    key: TabKeys.DEFENSIVE,
    label: "Carrego",
    children: <DefensiveWallet />,
  },
  {
    key: TabKeys.PICTURES,
    label: "Figuras",
    children: <PicturesWallet />,
  },
  {
    key: TabKeys.EXERCISE,
    label: "Exercício de opções",
    children: <ExerciseScreen />,
  },
];

const WalletPage = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data, isLoading } = useQuery<SimplifiedWallet[]>({
    queryKey: ["wallets", "simplified", "option"],
    queryFn: () => appApi.wallets.getSimplifiedWalletsList("stock"),
  });

  const wallets = data || [];

  if (isLoading) {
    return <Spin />;
  }

  const firstWalletsId = wallets && wallets.length > 0 ? wallets[0].id : "";

  const tab = searchParams.get("tab") || firstWalletsId;

  let selectedTab = items.find((item) => item.key === tab)
    ? tab
    : TabKeys.INCOME;
  return (
    <Tabs
      defaultActiveKey={selectedTab}
      activeKey={selectedTab}
      items={items}
      onChange={(activeKey) => setSearchParams({ tab: activeKey })}
      tabBarStyle={{ paddingLeft: 16, paddingRight: 16 }}
    />
  );
};

export default WalletPage;
