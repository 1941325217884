import { SetStateAction } from "react";
import type { ListResponse } from "../../api/types";
import ResponsiveTable from "../../components/ResponsiveTable";
import PriceDisplay from "../../components/priceDisplay";
import { Descriptions, DescriptionsProps } from "antd";
import { WalletPnlResult } from "../../api/models";
import { numberToCurrency } from "../../services/utils";

const PNLRecords = ({
  data,
  isFetching,
  page,
  setPage,
  additionalColumns,
  columnsAfterWallets,
}: {
  data: ListResponse<any>;
  isFetching: boolean;
  page: number;
  setPage: (value: SetStateAction<number>) => void;
  additionalColumns: any;
  columnsAfterWallets: any;
}) => {
  let columns = [
    ...additionalColumns,
    {
      title: "PNL do período",
      dataIndex: "pnlResult",
      render: (value: string) => <PriceDisplay price={value} />,
    },
    {
      title: "PNL Acumulado",
      dataIndex: "accumulatedPnlResult",
      render: (value: string) => <PriceDisplay price={value} />,
    },
  ];
  if (columnsAfterWallets) {
    columns = [...columns, ...columnsAfterWallets];
  }

  return (
    <ResponsiveTable
      dataSource={data?.results}
      columns={columns}
      loading={isFetching}
      rowKey="id"
      className="w-full"
      size="small"
      pagination={{
        current: page,
        onChange: (page) => {
          setPage(page);
        },
        total: data?.count,
        pageSize: 10,
        showSizeChanger: false,
      }}
      expandable={{
        expandedRowRender: (record) => {
          const items: DescriptionsProps["items"] = record?.wallets
            ?.map((wallet: WalletPnlResult) => {
              return {
                key: wallet.name,
                label: wallet.name,
                children: `${numberToCurrency(wallet.pnlResult)} ${wallet.overridenPnlResult ? "(sobrescrito)" : ""}`,
              };
            })
            .sort((a: any, b: any) => a.key.localeCompare(b.key));
          return <Descriptions items={items} />;
        },
      }}
    />
  );
};

export default PNLRecords;
