import { useState } from "react";
import {
  AreaChartOutlined,
  LockOutlined,
  LogoutOutlined,
  SettingOutlined,
  WalletOutlined,
  UnorderedListOutlined,
  ControlOutlined,
  CalculatorOutlined,
  DollarOutlined,
  SearchOutlined,
  RadarChartOutlined,
  RiseOutlined,
  PlayCircleOutlined,
  RobotOutlined,
} from "@ant-design/icons";

import type { MenuProps } from "antd";
import { Menu } from "antd";
import { useAuth } from "../providers/authProvider";
import { useNavigate } from "react-router-dom";

const MenuTopBar = ({ onPress }: { onPress?: () => void }) => {
  const [current, setCurrent] = useState("mail");
  const { logout, hasAIAccess, hasOptionsAccess } = useAuth();
  const navigate = useNavigate();
  const items: MenuProps["items"] = [
    {
      label: "Dashboard",
      key: "dashboard",
      icon: <AreaChartOutlined />,
    },
    {
      label: "Posições",
      key: "positions",
      icon: <UnorderedListOutlined />,
    },
    {
      label: "Carteiras",
      key: "wallets",
      icon: <WalletOutlined />,
    },
    hasOptionsAccess
      ? { label: "Opções", key: "options", icon: <RiseOutlined /> }
      : null,
    {
      label: "PNL",
      key: "pnl",
      icon: <CalculatorOutlined />,
    },
    {
      label: "Renda",
      key: "income",
      icon: <DollarOutlined />,
    },
    {
      label: "Monitor de opções",
      key: "monitor",
      icon: <RadarChartOutlined />,
    },
    {
      label: "Simulador",
      key: "simulator",
      icon: <PlayCircleOutlined />,
    },
    {
      label: "Consultas",
      key: "SubMenu",
      icon: <SearchOutlined />,
      children: [
        hasAIAccess
          ? {
              label: "Ranking IA",
              key: "ranking_ai",
              icon: <RobotOutlined />,
            }
          : null,
        {
          label: "B&S Calculadora",
          key: "bs_calculator",
          icon: <CalculatorOutlined />,
        },
        {
          label: "Busca de Ativos",
          key: "asset_search",
          icon: <SearchOutlined />,
        },
      ],
    },
    {
      label: "Conta",
      key: "accountSubMenu",
      icon: <SettingOutlined />,
      children: [
        {
          label: "Personalização",
          key: "personalization",
          icon: <SettingOutlined />,
        },
        {
          label: "Metatrader",
          key: "edit-account",
          icon: <ControlOutlined />,
        },
        {
          label: "Alterar senha",
          key: "change-password",
          icon: <LockOutlined />,
        },
        {
          label: "Sair",
          key: "logout",
          icon: <LogoutOutlined />,
        },
      ],
    },
  ];

  const onClick: MenuProps["onClick"] = (e) => {
    setCurrent(e.key);
    if (onPress) {
      onPress();
    }
    if (e.key === "logout") {
      logout();
    }
    if (e.key === "pnl") {
      navigate("/pnl");
    }
    if (e.key === "income") {
      navigate("/income");
    }
    if (e.key === "monitor") {
      navigate("/options-monitor");
    }
    if (e.key === "dashboard") {
      navigate("/dashboard");
    }
    if (e.key === "wallets") {
      navigate("/value-wallets");
    }
    if (e.key === "positions") {
      navigate("/positions");
    }
    if (e.key === "options") {
      navigate("/option-wallets");
    }
    if (e.key === "change-password") {
      navigate("/change-password");
    }
    if (e.key === "edit-account") {
      navigate("/edit-account");
    }
    if (e.key === "personalization") {
      navigate("/personalization");
    }
    if (e.key === "asset_search") {
      navigate("/asset-search");
    }
    if (e.key === "bs_calculator") {
      navigate("/bs-calculator");
    }
    if (e.key === "simulator") {
      navigate("/simulator");
    }
    if (e.key === "ranking_ai") {
      navigate("/ranking-ai");
    }
  };

  return (
    <Menu
      onClick={onClick}
      selectedKeys={[current]}
      mode="inline"
      items={items.filter((item) => item && item.key != "")}
      theme="dark"
    />
  );
};

export default MenuTopBar;
